import { darkTheme, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$5',
  '& .level-score-content': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const ProgressBarContainer = styled('div', {
  width: '100%',
  height: '$10',
  backgroundColor: '$neutral100',
  borderRadius: '$sm',
  overflow: 'hidden',
  position: 'relative',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral900',
  },
});

export const ProgressBar = styled('div', {
  height: '100%',
  transition: 'width 1s ease-in-out',
  backgroundColor: '$primary',
});

export const ScoreContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$5',
});

export const Score = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});
