import { darkTheme, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$30 0',
  '@sm': {
    padding: '120px 0',
  },

  '& .profile-not-found-image': {
    paddingBottom: '$46',
    '@sm': {
      paddingBottom: '$100',
    },
  },

  '& .title': {
    '@sm': {
      fontSize: 45,
    },
  },

  '& .description': {
    paddingBottom: '$46',
    '@sm': {
      paddingBottom: '$50',
      fontWeight: '$regular',
    },
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },

  '& .back-to-home': {
    width: 220,
    '@sm': {
      width: 228,
    },
  },
});
