import { darkTheme, Skeleton, styled, theme } from '@rango-dev/ui';

export const Container = styled('div', {
  backgroundColor: '$background',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '$xm',
  position: 'relative',
  padding: '$20 $0 $0',

  '&:hover': {
    '& .leaderboard-username': {
      textDecoration: 'underline',
    },
    '& .user-details': {
      '@lg': {
        backgroundColor: '$secondary100',
        [`.${darkTheme} &`]: {
          backgroundColor: '$neutral500',
        },
      },
    },
  },
  variants: {
    userRank: {
      '1': {
        order: 2,
        '& .user-rank-header': {
          padding: '$0 $25 34px',
        },
      },
      '2': {
        order: 1,
      },
      '3': {
        order: 3,
      },
    },
  },
});

export const UserRankHeader = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  '@lg': {
    padding: '$0 $25 $30',
    flexDirection: 'row',
  },
  '& .top-user-image': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '&.user-images-skeleton': {
      position: 'relative',
      '& div': {
        borderRadius: '100%',
      },
    },
  },
  '& .top-user-rank': {
    display: 'none',
    flexDirection: 'column',
    padding: '$10 $15',
    '@lg': {
      display: 'flex',
    },
  },
});

export const UserDetailsFooter = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '$15',
  marginTop: '35px',
  '@lg': {
    flexDirection: 'row',
    borderTop: '1px solid $neutral300',
    [`.${darkTheme} &`]: {
      borderColor: theme.colors.neutral300,
    },
    gap: '$0',
    marginTop: '$0',
    padding: '$15 $25',
  },
  variants: {
    isCurrentUser: {
      true: {
        margin: '$30 $5 $5',
        '@lg': {
          margin: '$30 0 0',
        },
      },
      false: {
        marginBottom: '$20',
        '@lg': {
          marginBottom: 0,
        },
      },
    },
  },
});

export const UserImage = styled('img', {
  borderRadius: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  '@sm': {
    borderWidth: '3px',
  },

  variants: {
    userRank: {
      '1': {
        borderColor: '#F8D34C',
      },
      '2': {
        borderColor: '#DFE2E9',
      },
      '3': {
        borderColor: '#D8923C',
      },
    },
  },
});

export const UserRank = styled('img', {
  position: 'absolute',
  bottom: -20,
});

export const UserRankSkeleton = styled(Skeleton, {
  position: 'absolute',
  bottom: -20,
});

export const CurrentUserTag = styled('div', {
  padding: '$2 $4',
  backgroundColor: '$secondary100',
  borderRadius: '$xs',

  '&.footer-current-user-tag': {
    display: 'block',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '$xm',
    borderBottomRightRadius: '$xm',
    width: '100%',
    marginTop: '$15',
    textAlign: 'center',
    '@lg': {
      display: 'none',
    },
  },
  '&.current-user-tag': {
    display: 'none',
    '@lg': {
      display: 'block',
    },
  },
});
