import { ReactElement } from 'react';
import {
  Container,
  Content,
  UserImageContent,
  TopUserRankSkeleton,
  UserImageSkeleton,
} from './TopUsers.styles';
import { Divider, Skeleton } from '@rango-dev/ui';
import { getTopUserImageSize } from './TopUsers.helpers';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

export function LoadingTopUsers(): ReactElement {
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      {Array.from({ length: 3 }).map((_, index) => {
        const rank = index + 1;
        return (
          <Content userRank={rank} key={index}>
            <UserImageContent>
              <UserImageSkeleton
                variant="circular"
                height={getTopUserImageSize(rank, isMobile)}
                width={getTopUserImageSize(rank, isMobile)}
              />
              <TopUserRankSkeleton variant="circular" height={32} width={32} />
            </UserImageContent>
            {!isMobile && <Divider size={36} />}

            <Skeleton variant="rounded" height={20} width={77} />
            {!isMobile && <Divider size={16} />}

            <Skeleton variant="rounded" height={24} width={60} />
          </Content>
        );
      })}
    </Container>
  );
}
