import { ReactElement } from 'react';
import { Skeleton, Typography } from '@rango-dev/ui';
import {
  Container,
  UserDetailsFooter,
  UserRankHeader,
  UserRankSkeleton,
} from './TopUserCard.styles';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { getUserImageSize } from './TopUserCard.helpers';
import { LoadingUserCardPropTypes } from './TopUserCard.types';

export function TopUserCardSkeleton(props: LoadingUserCardPropTypes): ReactElement {
  const { rank } = props;
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const isFirstUser = rank === 1;

  return (
    <Container userRank={rank}>
      <UserRankHeader>
        <div className="top-user-rank">
          <Typography size="large" variant={isFirstUser ? 'display' : 'headline'}>
            {rank}
          </Typography>
          <Typography size="medium" variant="label" color="neutral700">
            {t('Rank')}
          </Typography>
        </div>
        <div className="top-user-image user-images-skeleton">
          <Skeleton
            variant="circular"
            height={getUserImageSize(rank, isMobile)}
            width={getUserImageSize(rank, isMobile)}
          />
          <UserRankSkeleton variant="circular" height={32} width={32} />
        </div>
      </UserRankHeader>
      <UserDetailsFooter>
        <Skeleton variant="rounded" height={isMobile ? 16 : 24} width={isMobile ? 77 : 130} />
        <Skeleton variant="rounded" height={24} width={60} />
      </UserDetailsFooter>
    </Container>
  );
}
