import { Divider, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { EmptyStateContainer } from './Leaderboard.styles';

export function LeaderboardEmptyState() {
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();

  return (
    <EmptyStateContainer>
      <img
        src={
          isDarkMode
            ? '/imgs/common/empty-welcome-section-dark.svg'
            : '/imgs/common/empty-welcome-section-light.svg'
        }
        alt="Error retrieving leaderboard"
        width={isMobile ? 213 : 285}
        height={isMobile ? 174 : 228}
      />
      <Divider size={20} />

      <Typography variant="title" size={isMobile ? 'xmedium' : 'medium'} color="$error500">
        {t('An unexpected error occurred')}
      </Typography>
      <Divider size={isMobile ? 10 : 4} />
      <Typography className="_text-description" variant="body" size="medium">
        {t('Please try refreshing the page')}
      </Typography>
    </EmptyStateContainer>
  );
}
