import { ReactElement, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, DesktopContainer } from './Profile.styles';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { ProfileSummary } from '../../components/Profile';
import { useTranslation } from 'react-i18next';
import { SimpleNotification } from '../../components/SimpleNotification';
import { ProfileUserData } from '../../components/Profile/ProfileUserData';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  fetchProfileDetails,
  fetchProfileLeaderboard,
  fetchProfileSummary,
  showPrivateProfileSelector,
  resetMergeStatus,
  resetUserData,
} from '../../state/ProfileSlice';
import { ProfileMobileMode } from '../../components/Profile/ProfileMobileMode';
import { Cancellable } from '../../types';
import { LoggedInUserSidebar } from '../../components/Profile/LoggedInUserSidebar';
import { Helmet } from 'react-helmet';
import { UserProfileNotFound } from '../../components/Profile/UserProfileNotFound';

function Profile(): ReactElement {
  const isLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const profileAuth = useAppSelector((state) => state.profile.profileAuth);
  const profileSummary = useAppSelector((state) => state.profile.profileSummary);
  const profileDetails = useAppSelector((state) => state.profile.profileDetails);
  const accountMerged = useAppSelector((state) => state.profile.profileAuth.accountMerged);
  const params = useParams<{ username: string }>();
  const showPrivateProfile = useAppSelector((state) =>
    showPrivateProfileSelector(state, params.username),
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useWindowDimensions();
  const fetchProfileSummaryPromise = useRef<Cancellable | null>(null);
  const fetchProfileDetailsPromise = useRef<Cancellable | null>(null);
  const fetchProfileLeaderboardPromise = useRef<Cancellable | null>(null);

  const metaTitle = t('Rango Exchange | {{username}} Rango Profile - Overall Score & Ranking', {
    username: params?.username || '',
  });
  const metaDescription = t(
    'View the Rango Exchange profile for {{username}} to explore their score, trading volume, and overall ranking.',
    { username: params?.username || 'username' },
  );

  useEffect(() => {
    if (params.username) {
      fetchProfileSummaryPromise.current = dispatch(
        fetchProfileSummary({ username: params.username }),
      );
    }
    return () => {
      fetchProfileSummaryPromise.current?.abort();
    };
  }, [params.username]);

  useEffect(() => {
    if (!profileAuth.loading && showPrivateProfile && !profileDetails.data) {
      fetchProfileDetailsPromise.current = dispatch(fetchProfileDetails());
    }
    return () => {
      fetchProfileDetailsPromise.current?.abort();
    };
  }, [profileAuth.loading, profileDetails.data?.id, showPrivateProfile]);

  useEffect(() => {
    if (params.username) {
      fetchProfileLeaderboardPromise.current = dispatch(
        fetchProfileLeaderboard({ username: params.username }),
      );
    }
    return () => {
      fetchProfileLeaderboardPromise.current?.abort();
    };
  }, [params.username]);

  useEffect(() => {
    return () => {
      dispatch(resetUserData());
    };
  }, [params.username]);

  const userNotFound = !profileSummary.loading && !profileSummary.data;

  if (params.username && userNotFound) {
    return <UserProfileNotFound username={params.username} />;
  }

  return (
    <Container loggedIn={isLoggedIn}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <SimpleNotification
        message={t('Something went wrong. Please try again.')}
        open={!!profileSummary.error || !!profileDetails.error}
        setOpen={() => {
          //
        }}
        severity="error"
      />
      <SimpleNotification
        message={t('Wallet added to your profile.')}
        open={accountMerged}
        setOpen={(open) => {
          if (!open) {
            dispatch(resetMergeStatus());
          }
        }}
        severity="success"
      />
      {isDesktop && <LoggedInUserSidebar />}

      {!isDesktop ? (
        <ProfileMobileMode />
      ) : (
        <DesktopContainer>
          <ProfileSummary />
          {showPrivateProfile && <ProfileUserData />}
        </DesktopContainer>
      )}
    </Container>
  );
}

export default Profile;
