export const GTMEvents = {
  CONFIRM_SWAP: 'event_confirm_swap',
  CLICK_SWAP: 'event_click_swap',
  CLICK_WALLET_CONNECT_BOT: 'event_click_wallet_connect_bot',
  SELECT_LANGUAGE: 'event_select_language',
  LANGUAGE_ICON_CLICK: 'event_language_icon_click',
  TX_HISTORY: 'event_tx_history',
  CLICK_WALLET_CONNECT_TOP: 'event_click_wallet_connect_top',
  RELOAD_BALANCE: 'event_reload_balance',
  INVITE_FRIEND: 'event_invite_friend',
  CREATE_AFFILIATE_LINK: 'event_create_affiliate_link',
  WALLET_CONNECTED: 'event_wallet_connected',
  WALLET_DISCONNECTED: 'event_wallet_disconnected',
  WALLET_INITIATED: 'event_wallet_initiated',
  WALLET_FAILED: 'event_wallet_failed',
  SUCCEEDED_TRANSACTION: 'event_succeeded_transaction',
  FAILED_TRANSACTION: 'event_failed_transaction',
  CHANGE_CONSENT: 'change_consent',
};
