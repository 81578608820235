import { ReactElement } from 'react';
import {
  Container,
  Content,
  UserImage,
  TopUserRank,
  UserImageContent,
  ScoreContent,
  StyledLink,
} from './TopUsers.styles';
import { Divider, ScoreIcon, Typography } from '@rango-dev/ui';
import { PropTypes } from './TopUsers.types';
import { getTopUserImageSize } from './TopUsers.helpers';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { displayUserRank } from '../../ProfileSummary/ProfileSummary.helpers';
import { shortenUsername } from '../Leaderboard.helpers';
import { CenteredFlexBox, Score } from '../List/List.styles';

export function TopUsers(props: PropTypes): ReactElement {
  const { list } = props;
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      {list.map((item) => {
        const isFirstUser = item.rank === 1;

        return (
          // eslint-disable-next-line jsx-id-attribute-enforcement/missing-ids
          <StyledLink
            userRank={item.rank}
            className="profile-page-public-leaderboard-top-user-link"
            to={`../profile/${item.username}`}
            key={item.id}>
            <Content>
              <UserImageContent>
                <UserImage
                  userRank={item.rank}
                  src={item.avatar}
                  height={getTopUserImageSize(item.rank, isMobile)}
                  width={getTopUserImageSize(item.rank, isMobile)}
                />
                <TopUserRank
                  src={`/imgs/leaderboard/rank${item.rank}.svg`}
                  alt={`Rank ${item.rank}`}
                  width={32}
                  height={32}
                />
              </UserImageContent>
              {!isMobile && <Divider size={36} />}
              <Typography
                className="leaderboard-username"
                variant="body"
                size={isMobile ? 'small' : 'medium'}>
                {shortenUsername({ username: item.username })}
              </Typography>
              {!isMobile && <Divider size={16} />}

              <CenteredFlexBox>
                <ScoreContent>
                  <ScoreIcon color="warning" size={16} />
                </ScoreContent>
                <Divider direction="horizontal" size={8} />
                <Score variant="title" size={isFirstUser ? 'xmedium' : 'small'}>
                  {displayUserRank(item.score)}
                </Score>
              </CenteredFlexBox>
            </Content>
          </StyledLink>
        );
      })}
    </Container>
  );
}
