import { Modal, styled } from '@rango-dev/ui';
import { ComponentProps } from 'react';

export const Container = styled('div', {});

export const modalStyles: ComponentProps<typeof Modal>['styles'] = {
  container: {
    backgroundColor: '$background',
    borderRadius: '$primary',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: '60vh',
    minHeight: '450px',
    '@sm': {
      minHeight: '250px',
      width: '410px',
    },
  },
  content: {
    padding: '0 $20 $20 $20',
    overflow: 'hidden',
  },
};

export const WalletList = styled('ul', {
  overflowY: 'auto',
});
