import { ReactElement, useEffect, useRef } from 'react';
import { Divider } from '@rango-dev/ui';
import { Cancellable } from '../../types';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchPublicLeaderboard } from '../../state/ProfileSlice';
import { Container, ListWrapper, SubTitle, Title, TopUserContainer } from './Leaderboard.styles';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { List } from '../../components/Profile/Leaderboard/List';
import { ListSkeleton } from '../../components/Profile/Leaderboard/List/ListSkeleton';
import { useTranslation } from 'react-i18next';
import { TopUserCard } from '../../components/Leaderboard/TopUserCard/TopUserCard';
import { TopUserCardSkeleton } from '../../components/Leaderboard/TopUserCard/TopUserCardSkeleton';
import { Helmet } from 'react-helmet';
import { UserBanner } from '../../components/Profile/UserBanner';

export function Leaderboard(): ReactElement {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const { data: profileDetails } = useAppSelector((state) => state.profile.profileDetails);

  const { data: leaderboard, loading } = useAppSelector((state) => state.profile.publicLeaderboard);

  const topUser = leaderboard?.slice(0, 3);
  const otherUser = leaderboard?.slice(3);

  const fetchPublicLeaderboardPromise = useRef<Cancellable | null>(null);

  const metaTitle = t('Rango Exchange | Leaderboard - Top Rangonatus, Scores & Rankings');
  const metaDescription = t(
    'Explore the Rango Exchange leaderboard to see the top traders, their scores, trading volumes, and rankings. View the high rank Rangonauts in the competition!',
  );

  useEffect(() => {
    fetchPublicLeaderboardPromise.current = dispatch(
      fetchPublicLeaderboard({ page: 0, offset: 20 }),
    );
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <Title>{t('Leaderboard')}</Title>
      <Divider size={10} />
      <SubTitle size="large" variant={isMobile ? 'body' : 'title'} color="neutral700">
        {t('Track your score and rank in Rango Exchange')}
      </SubTitle>
      <Divider size={isMobile ? 10 : 20} />
      <Divider size={isMobile ? 10 : 24} />
      <UserBanner />
      <Divider size={isMobile ? 10 : 30} />
      <TopUserContainer>
        {!topUser || loading
          ? Array.from({ length: 3 }).map((_, index) => (
              <TopUserCardSkeleton key={index} rank={index + 1} />
            ))
          : topUser?.map((user) => <TopUserCard key={user.id} user={user} />)}
      </TopUserContainer>
      <Divider size={isMobile ? 10 : 16} />

      <ListWrapper>
        {!otherUser || loading ? (
          <ListSkeleton maxLength={20} />
        ) : (
          <List
            list={otherUser}
            showCurrentUserTag
            itemClassName="leaderboard-item"
            userId={profileDetails?.id}
          />
        )}
      </ListWrapper>
    </Container>
  );
}
