type Params = { username: string; startChars?: number; endChars?: number };

export function shortenUsername(params: Params) {
  const { username, startChars = 6, endChars = 4 } = params;
  const maxLength = startChars + endChars;
  if (username.length <= maxLength) {
    return username;
  }
  return `${username.slice(0, startChars)}...${username.slice(-endChars)}`;
}

export const getUserImageSize = (isCurrentUser: boolean, isMobile: boolean) => {
  if (isCurrentUser) {
    return isMobile ? 32 : 40;
  }
  return 24;
};
