import { darkTheme, styled } from '@rango-dev/ui';

export const AlignedFlexBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const FavoriteItemsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$15',
  '& .favorite-item-title': {
    color: '$neutral700',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
  '&:not(:last-child)': {
    borderBottom: '1px solid $neutral300',
  },
  [`.${darkTheme} &`]: {
    borderColor: '$neutral400',
  },
  '@sm': {
    '&:nth-child(-n+2)': {
      borderBottom: '1px solid $neutral300',
    },
    '&:nth-child(n+3)': {
      borderBottom: 'none',
    },
  },
});

export const Container = styled('div', {
  flex: 1,
  gridTemplateColumns: '1fr',
  backgroundColor: '$background',
  borderRadius: '$xm',
  '@sm': {
    display: 'grid',
    width: '100%',
    gridGap: '$20',
  },
  '@md': {
    gridTemplateColumns: '1fr 1fr',
  },
});
