import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Tooltip as UiTooltip } from '@rango-dev/ui';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { hasTouchScreen } from '../../../utils/generalFunctions/common';
import { StyledIconButton } from './Tooltip.styles';
import { PropTypes } from './Tooltip.types';

export function Tooltip(props: PropsWithChildren<PropTypes>) {
  const { showInTouchDevices = true, ...tooltipProps } = props;
  const isTouchDevice = hasTouchScreen();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useClickOutside(buttonRef, () => setOpen(false));

  const handleClickTrigger = () => setOpen((prev) => !prev);

  useEffect(() => {
    const handler = () => {
      setOpen(false);
    };
    window.addEventListener('touchmove', handler);

    return () => window.removeEventListener('touchmove', handler);
  }, []);

  if (isTouchDevice && showInTouchDevices) {
    return (
      // eslint-disable-next-line jsx-id-attribute-enforcement/missing-ids
      <StyledIconButton variant="ghost" ref={buttonRef} onClick={handleClickTrigger}>
        <UiTooltip {...tooltipProps} align="center" sideOffset={8} open={open} />
      </StyledIconButton>
    );
  }

  return <UiTooltip {...props} />;
}
