import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  getLoginMessage,
  getMergeAccountMessage,
  logIn,
  mergeAccount,
  logOut,
  getProfileSummary,
  getPublicProfileSummary,
  getProfileDetails,
  getProfileLeaderboard,
  getPublicProfileLeaderboard,
  getPublicLeaderboard,
} from '../../api/Profile';
import {
  ProfileSummary,
  ProfileDetails,
  ProfileLeaderboard,
  PublicLeaderboardRequest,
} from '../../types';
import { throwIfAborted, UserUnauthorizedError, getErrorMessage } from '../../utils/Errors';
import { store } from '../Store';
import { SignMessageParams } from './types';

export const signMessage = createAsyncThunk<
  ProfileSummary | null,
  SignMessageParams,
  { state: ReturnType<typeof store.getState>; rejectValue: string }
>('profile/signMessage', async (params, { rejectWithValue, getState, dispatch, signal }) => {
  const { account, getSigners, callback } = params;
  const {
    meta: { blockchains },
    profile: { profileAuth, isLoggedIn },
  } = getState();
  const { numberOfRetries } = profileAuth;
  throwIfAborted(signal);
  const signer = await getSigners(account.walletType);
  const blockchainMeta = blockchains.find((blockchain) => blockchain.name === account.blockchain);
  if (!blockchainMeta) {
    return rejectWithValue('Something went wrong. Please try again.');
  }
  const requestSignMessage = !isLoggedIn ? getLoginMessage : getMergeAccountMessage;
  try {
    throwIfAborted(signal);
    const { message, messageId } = await requestSignMessage(
      {
        address: account.address,
        blockchain: account.blockchain,
      },
      signal,
    );
    throwIfAborted(signal);
    const signature = await signer
      .getSigner(blockchainMeta.type)
      .signMessage(message, account.address, blockchainMeta.chainId);

    const requestAuth = !isLoggedIn ? logIn : mergeAccount;
    throwIfAborted(signal);
    const { profile } = await requestAuth({ messageId, signature }, signal);
    if (profile) {
      callback?.(profile);
    }
    return profile;
  } catch (error) {
    if (signal.aborted || axios.isCancel(error)) {
      return null;
    }

    const shouldLogOutAndRetry =
      !isLoggedIn && error instanceof UserUnauthorizedError && numberOfRetries === 0;

    if (shouldLogOutAndRetry) {
      await logOut(signal);
      // Use a timeout to dispatch signMessage after incrementing the numberOfRetries.
      setTimeout(() => dispatch(signMessage(params)), 0);
      return null;
    } else {
      return rejectWithValue(getErrorMessage(error));
    }
  }
});

export const fetchProfileSummary = createAsyncThunk<
  ProfileSummary | null,
  { username: string; public?: boolean },
  {
    state: ReturnType<typeof store.getState>;
    rejectValue: string | null;
    fulfilledMeta: { public?: boolean };
  }
>(
  'profile/fetchProfileSummary',
  async (params, { rejectWithValue, signal, getState, fulfillWithValue, dispatch }) => {
    const state = getState().profile;
    const publicProfile =
      params.public ||
      !state.isLoggedIn ||
      (state.isLoggedIn && state.username !== params.username);

    const request = publicProfile
      ? getPublicProfileSummary.bind(null, params.username)
      : getProfileSummary;

    try {
      const profileSummary = await request(signal);
      return fulfillWithValue(profileSummary.profile, { public: publicProfile });
    } catch (error) {
      if (error instanceof UserUnauthorizedError) {
        dispatch(fetchProfileSummary({ username: params.username, public: true }));
        return rejectWithValue(null);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const fetchProfileDetails = createAsyncThunk<
  ProfileDetails | null,
  undefined,
  { state: ReturnType<typeof store.getState>; rejectValue: string | null }
>('profile/fetchProfileDetails', async (_, { rejectWithValue, signal }) => {
  try {
    const profileDetails = await getProfileDetails(signal);
    return profileDetails.profile;
  } catch (error) {
    if (error instanceof UserUnauthorizedError) {
      return rejectWithValue(null);
    }
    return rejectWithValue(getErrorMessage(error));
  }
});

export const fetchProfileLeaderboard = createAsyncThunk<
  ProfileLeaderboard[] | null,
  { username: string; public?: boolean },
  { state: ReturnType<typeof store.getState>; rejectValue: string | null }
>(
  'profile/fetchProfileLeaderboard',
  async (params, { rejectWithValue, signal, getState, dispatch }) => {
    const state = getState().profile;
    const publicProfile =
      params.public ||
      !state.isLoggedIn ||
      (state.isLoggedIn && state.username !== params.username);

    const request = publicProfile
      ? getPublicProfileLeaderboard.bind(null, params.username)
      : getProfileLeaderboard;

    try {
      const profileLeaderboard = await request(signal);
      return profileLeaderboard.data;
    } catch (error) {
      if (error instanceof UserUnauthorizedError) {
        dispatch(fetchProfileLeaderboard({ username: params.username, public: true }));
        return rejectWithValue(null);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const fetchPublicLeaderboard = createAsyncThunk<
  ProfileLeaderboard[] | null,
  PublicLeaderboardRequest,
  { state: ReturnType<typeof store.getState>; rejectValue: string }
>('profile/fetchPublicLeaderboard', async (params, { rejectWithValue, signal }) => {
  try {
    const leaderboard = await getPublicLeaderboard(params, signal);
    return leaderboard.data;
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});
