import { Image, Skeleton, Typography } from '@rango-dev/ui';
import { Tooltip } from '../Tooltip';
import { Container } from './ProfileCardItem.styles';
import { PropTypes } from './ProfileCardItem.types';
import { displayUserRank } from '../ProfileSummary/ProfileSummary.helpers';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function ProfileCardItem(props: PropTypes) {
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      <Image src={props.icon} size={isMobile ? 32 : 48} />
      <div className="content">
        <div className="value">
          {props.loading ? (
            <Skeleton width={isMobile ? 60 : 80} height={24} variant="rounded" />
          ) : (
            <>
              <Typography variant="title" size={isMobile ? 'small' : 'xmedium'}>
                {displayUserRank(props.value)}
              </Typography>
              <Tooltip description={props.description} iconSize={18} sideOffset={16} />
            </>
          )}
        </div>
        <Typography
          variant={isMobile ? 'label' : 'title'}
          size={isMobile ? 'medium' : 'small'}
          color={isDarkMode ? 'neutral900' : 'neutral700'}>
          {props.title}
        </Typography>
      </div>
    </Container>
  );
}
