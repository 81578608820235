import { Divider, DoneIcon, Typography } from '@rango-dev/ui';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ProfileWalletsModal } from '../ProfileWalletsModal';
import {
  ButtonContent,
  MainSection,
  Content,
  Description,
  MobileSideBarContainer,
  SidebarContainer,
  Step,
  StepNumber,
  Steps,
  StepTitle,
  StyledButton,
  Container,
  UnauthenticatedImage,
} from './LogIn.styles';
import { useProfile } from '../../../hooks/useProfile';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { StepSeparator } from './StepSeparator';
import {
  BLOCKCHAINS_THAT_SUPPORT_PROFILE,
  WALLETS_WITHOUT_SIGN_MESSAGE_METHOD,
} from '../../../hooks/useProfile/useProfile.constants';
import ConnectWalletModal from '../../ConnectWalletModal';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';
import { SimpleNotification } from '../../SimpleNotification';
import { clearAuthError, logOutUser, fetchPublicLeaderboard } from '../../../state/ProfileSlice';
import { Navigate } from 'react-router-dom';
import { logOut } from '../../../api/Profile';
import { Leaderboard } from '../Leaderboard';
import { Cancellable } from '../../../types';
import { Helmet } from 'react-helmet';

export function LogIn(): ReactElement {
  const loadingMetaStatus = useAppSelector((state) => state.meta.loadingStatus);
  const authError = useAppSelector((state) => state.profile.profileAuth.error);
  const username = useAppSelector((state) => state.profile.username);
  const isLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { compatibleAccounts, readyToSign } = useProfile();
  const [showProfileWalletsModal, setShowProfileWalletsModal] = useState(false);
  const [showConnectWalletsModal, setShowConnectWalletsModal] = useState(false);
  const toggleConnectWalletsModal = () => setShowConnectWalletsModal((prevState) => !prevState);
  const { isMobile, isDesktop } = useWindowDimensions();

  const fetchPublicLeaderboardPromise = useRef<Cancellable | null>(null);

  const {
    data: publicLeaderboard,
    loading: publicLeaderboardLoading,
    error: publicLeaderboardError,
  } = useAppSelector((state) => state.profile.publicLeaderboard);
  const metaTitle = t('Rango Exchange | Rango Profile - View Your Score & Ranking');
  const metaDescription = t(
    'Check your Rango Exchange profile to see your score, trading volume, and ranking among Rangonauts! View your most used Blockchain, DEX or Bridge!',
  );

  const signWalletDisabled = !readyToSign;

  const handleConnectWalletsModalExit = () => {
    if (readyToSign) {
      setShowProfileWalletsModal(true);
    }
  };

  const handleLogOut = async () => {
    await logOut();
    dispatch(logOutUser());
  };

  useEffect(() => {
    if (signWalletDisabled && showProfileWalletsModal) {
      setShowProfileWalletsModal(false);
    }
  }, [signWalletDisabled]);

  useEffect(() => {
    if (!isLoggedIn) {
      fetchPublicLeaderboardPromise.current = dispatch(
        fetchPublicLeaderboard({ page: 0, offset: 10 }),
      );
    }
    return () => {
      fetchPublicLeaderboardPromise.current?.abort();
    };
  }, [isLoggedIn]);

  if (isLoggedIn && username) {
    return <Navigate to={username} replace />;
  }

  if (isLoggedIn) {
    handleLogOut();
  }

  return (
    <Container>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <SimpleNotification
        message={t('An error occurred during login. Please try again.')}
        open={!!authError}
        setOpen={(open) => {
          if (!open) {
            dispatch(clearAuthError());
          }
        }}
        severity="error"
      />
      <MainSection>
        <ConnectWalletModal
          open={showConnectWalletsModal}
          onClose={toggleConnectWalletsModal}
          blockchains={BLOCKCHAINS_THAT_SUPPORT_PROFILE}
          excludedWallets={WALLETS_WITHOUT_SIGN_MESSAGE_METHOD}
          title={t('Choose wallet for your profile')}
          closeAfterSelect={true}
          onExit={handleConnectWalletsModalExit}
        />
        <Content>
          <UnauthenticatedImage
            alt="unauthenticated profile image"
            title="unauthenticated profile image"
            src="/imgs/profile/profile-overview.png"
          />
          <Divider direction="vertical" size={isMobile ? '30' : '16'} />
          <Typography variant="headline" size={isMobile ? 'xsmall' : 'small'}>
            {t('Sign wallet to see your stats!')}
          </Typography>
          <Description variant="body" size="small">
            {t('Track your score and stats in your profile')}
          </Description>
          <Steps>
            <Step>
              <StepNumber done={readyToSign}>
                {readyToSign ? (
                  <DoneIcon size={16} color="white" />
                ) : (
                  <Typography variant="title" size="xmedium" color="secondary">
                    1
                  </Typography>
                )}
              </StepNumber>
              <StepTitle variant="label" size="medium" color="secondary">
                {t('Connect Wallet')}
              </StepTitle>
            </Step>
            <div className="step-separator-container">
              <StepSeparator disabled={signWalletDisabled} />
            </div>
            <Step>
              <StepNumber disabled={signWalletDisabled}>
                <Typography variant="title" size="xmedium" color="secondary">
                  2
                </Typography>
              </StepNumber>
              <StepTitle
                variant="label"
                size="medium"
                color="secondary"
                disabled={signWalletDisabled}>
                {t('Sign Wallet')}
              </StepTitle>
            </Step>
          </Steps>
          <StyledButton
            id={`profile-page-${readyToSign ? 'sign' : 'connect'}-wallet-btn`}
            variant="contained"
            type="primary"
            size="medium"
            fullWidth={isMobile}
            disabled={loadingMetaStatus !== 'success'}
            onClick={() => {
              if (!readyToSign) {
                setShowConnectWalletsModal(true);
              } else {
                setShowProfileWalletsModal(true);
              }
            }}>
            <ButtonContent>
              {signWalletDisabled ? t('Connect Your Wallet') : t('Sign Your Wallet')}
            </ButtonContent>
          </StyledButton>
          <ProfileWalletsModal
            open={showProfileWalletsModal}
            onClose={() => setShowProfileWalletsModal(false)}
            accounts={compatibleAccounts}
          />
        </Content>
      </MainSection>
      {isDesktop && (
        <SidebarContainer>
          <Leaderboard
            isPublic
            title={t('All Time Leaderboard')}
            list={publicLeaderboard}
            loading={publicLeaderboardLoading}
            error={publicLeaderboardError}
          />
          <Divider size={24} />

          <Campaigns />
        </SidebarContainer>
      )}
      {!isDesktop && (
        <MobileSideBarContainer>
          <Leaderboard
            isPublic
            title={t('All Time Leaderboard')}
            list={publicLeaderboard}
            loading={publicLeaderboardLoading}
            error={publicLeaderboardError}
          />
          <Divider size={16} />
          <Campaigns />
        </MobileSideBarContainer>
      )}
    </Container>
  );
}
