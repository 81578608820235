import { Image, Divider, ScoreIcon, Skeleton, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { shortenUsername } from '../Leaderboard/Leaderboard.helpers';
import { displayUserRank } from '../ProfileSummary/ProfileSummary.helpers';
import { useEffect, useRef } from 'react';
import { Cancellable } from '../../../types';
import { fetchProfileSummary } from '../../../state/ProfileSlice';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import {
  Container,
  Details,
  Info,
  LogInButton,
  RankAndAvatar,
  RankContainer,
  ScoreContainer,
  Separator,
  UserSummary,
} from './UserBanner.styles';

export function UserBanner() {
  const isLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);
  const { username } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const fetchProfileSummaryPromise = useRef<Cancellable | null>(null);

  const handleLogin = () => navigate('../profile');

  useEffect(() => {
    if (isLoggedIn && username) {
      fetchProfileSummaryPromise.current = dispatch(fetchProfileSummary({ username }));
    }
  }, [isLoggedIn, username]);

  if (isLoggedIn && loading) {
    return <Skeleton height={139} variant="rounded" />;
  }

  return (
    <Container isLoggedIn={isLoggedIn}>
      <Info>
        <Typography
          className="user-banner-text"
          variant="headline"
          size={isMobile ? 'xsmall' : 'large'}
          color="background">
          {isLoggedIn ? t('Your score and rank') : t('Track Your Progress Now')}
        </Typography>
        {!isMobile && <Divider size={'4'} />}
        <Typography className="user-banner-text" variant="title" size="small" color="background">
          {isLoggedIn ? t('Refresh in 30 seconds') : t('Log in to see your score and ranking')}
        </Typography>
      </Info>
      <Separator />
      <Details>
        {isLoggedIn && (
          <UserSummary>
            <RankAndAvatar>
              <RankContainer>
                <Typography
                  className="user-banner-text"
                  variant="title"
                  size="small"
                  color="background">
                  {profileSummary?.rank}
                </Typography>
              </RankContainer>
              <Divider size={16} direction="horizontal" />
              <Image size={isMobile ? 24 : 48} src={profileSummary?.avatar} />
              <Divider size={isMobile ? 10 : 16} direction="horizontal" />
              <Typography
                className="user-banner-text"
                variant={isMobile ? 'body' : 'title'}
                size={isMobile ? 'small' : 'xmedium'}
                color="background">
                {profileSummary?.username
                  ? shortenUsername({
                      username: profileSummary?.username,
                      startChars: isMobile ? 6 : 10,
                      endChars: 5,
                    })
                  : ''}
              </Typography>
            </RankAndAvatar>
            <ScoreContainer>
              <ScoreIcon color="white" size={isMobile ? 20 : 24} />
              <Typography
                className="user-banner-text"
                variant={isMobile ? 'label' : 'headline'}
                size={isMobile ? 'medium' : 'xsmall'}
                color="background">
                {profileSummary?.score ? displayUserRank(profileSummary?.score) : ''}
              </Typography>
            </ScoreContainer>
          </UserSummary>
        )}
        {!isLoggedIn && (
          <LogInButton
            variant="contained"
            type="secondary"
            id="leaderboard-page-top-banner-login-button"
            onClick={handleLogin}>
            {t('Log In')}
          </LogInButton>
        )}
      </Details>
    </Container>
  );
}
