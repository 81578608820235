import { InfoIcon, Typography } from '@rango-dev/ui';
import { IconContainer, tooltipStyles } from './Tooltip.styles';
import { PropTypes } from './Tooltip.types';
import { getContainer } from '../../../utils/generalFunctions/common';
import { Tooltip as TooltipComponent } from '../../common/Tooltip';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function Tooltip(props: PropTypes) {
  const { description, sideOffset, iconSize = 12 } = props;
  const { isMobile } = useWindowDimensions();

  return (
    <TooltipComponent
      side="bottom"
      align="start"
      styles={tooltipStyles}
      sideOffset={sideOffset}
      container={getContainer()}
      // collision padding with a value greater than the height of the mobile menu
      collisionPadding={isMobile ? { bottom: 80 } : undefined}
      content={
        <Typography variant="body" size="small">
          {description}
        </Typography>
      }>
      <IconContainer>
        <InfoIcon size={iconSize} color="gray" />
      </IconContainer>
    </TooltipComponent>
  );
}
