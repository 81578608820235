import { Ref, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@rango-dev/ui';
import { NavLinkPropTypes } from './NavLink.types';
import { BadgeContainer, LinkContainer, linkStyles } from './NavLink.styles';

function NavLinkComponent(props: NavLinkPropTypes, ref: Ref<HTMLDivElement>) {
  const { location, title, onClick, isActive, id, badge } = props;
  const externalLink = !!location.includes('http');

  return (
    <LinkContainer onClick={onClick} ref={ref} isActive={isActive}>
      <Link
        to={location}
        id={id}
        target={externalLink ? '_blank' : '_self'}
        rel={externalLink ? 'noreferrer' : undefined}>
        <Typography size="medium" variant="title" className={`cursor-pointer ${linkStyles()}`}>
          {title}
        </Typography>
      </Link>
      {badge && <BadgeContainer>{badge}</BadgeContainer>}
    </LinkContainer>
  );
}

export const NavLink = forwardRef(NavLinkComponent);
