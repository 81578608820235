import { BullhornIcon } from '@rango-dev/ui';
import { HeaderIcon } from '../common';
import { Link } from 'react-router-dom';

export const WhatsNew = () => (
  <Link
    id="header-news-icon-btn"
    className="beamerTrigger mr-2"
    to={'https://updates.rango.exchange'}
    target="_blank"
    rel="noreferrer">
    <HeaderIcon className="relative flex h-full w-full cursor-pointer items-center justify-center rounded-full px-2.5">
      <BullhornIcon size={20} color="black" />
    </HeaderIcon>
  </Link>
);
