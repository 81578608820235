import { t } from 'i18next';
import { useState } from 'react';
import { Container, TabsContainer, StyledTabs } from './ProfileMobileMode.styles';
import { ProfileSummaryMobile } from './ProfileSummaryMobile';
import { ProfileUserData } from '../ProfileUserData';
import { Leaderboard } from '../Leaderboard';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useParams } from 'react-router-dom';

export function ProfileMobileMode() {
  const { isLoggedIn, username } = useAppSelector((state) => state.profile);
  const params = useParams<{ username: string }>();
  const showPrivateProfile = isLoggedIn && username === params.username;

  const tabs = {
    summary: { id: 'summary', title: t('Summary') },
    ...(showPrivateProfile && { stats: { id: 'stats', title: t('Stats') } }),
    profileWallets: { id: 'leaderboard', title: t('Leaderboard') },
  };

  const [selectedTab, setSelectedTab] = useState(tabs.summary.id);
  const {
    data: profileLeaderboard,
    loading: profileLeaderboardLoading,
    error: profileLeaderboardError,
  } = useAppSelector((state) => state.profile.profileLeaderboard);

  const renderContent = () => {
    switch (selectedTab) {
      case tabs.summary.id:
        return <ProfileSummaryMobile />;
      case tabs.stats?.id:
        return <ProfileUserData />;
      case tabs.profileWallets.id:
        return (
          <Leaderboard
            list={profileLeaderboard}
            loading={profileLeaderboardLoading}
            error={profileLeaderboardError}
          />
        );

      default:
        return <ProfileSummaryMobile />;
    }
  };
  return (
    <Container>
      <TabsContainer>
        <StyledTabs
          items={Object.values(tabs)}
          onChange={(item) => {
            if (typeof item.id === 'string') {
              setSelectedTab(item.id);
            }
          }}
          type="secondary"
          value={selectedTab}
        />
      </TabsContainer>
      {renderContent()}
    </Container>
  );
}
