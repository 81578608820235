import { darkTheme, styled, Typography } from '@rango-dev/ui';

export const CenteredFlexBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const Content = styled('div', {
  borderBottom: '1px solid $neutral300',
  padding: '$15 $20',
  cursor: 'pointer',
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: '$neutral100',
    '& .leaderboard-username': {
      textDecoration: 'underline',
    },
  },
  '@sm': {
    padding: '$15 35px',
  },
});

export const Row = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
});

export const Rank = styled(Typography, {
  width: 68,
  borderRadius: '$primary',
  textAlign: 'center',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$neutral400',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral300',
  },
});

export const Score = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});

export const UserNameContent = styled('div', {
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  gap: 5,
  '@md': {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
});
