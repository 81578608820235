import { configureStore } from '@reduxjs/toolkit';
import { persistProfileState, loadPersistedState, walletDetailsMiddleware } from './Middlewares';
import RootReducer from './RootReducer';

export const store = configureStore({
  reducer: RootReducer,
  preloadedState: loadPersistedState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(walletDetailsMiddleware).concat(persistProfileState),
});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
