import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, Divider, Typography } from '@rango-dev/ui';
import {
  ButtonContainer,
  CheckboxStyle,
  CustomizeCookiesModalContent,
  CustomizeCookiesModalDivider,
  CustomizeCookiesModalHeader,
  CustomizeCookiesModalStyles,
  CustomizeModalCloseButton,
} from './CookieConsent.styles';
import { Modal } from '../Modal';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { CustomizeCookiesModalProps } from './CookieConsent.types';
import { useEffect, useState } from 'react';
import { getCookie } from '../../utils/generalFunctions/common';
import { COOKIE_CONSENT_VALUE, USER_COOKIE_CONSENT } from '../../constants/common';

export function CustomizeCookiesModal(props: CustomizeCookiesModalProps) {
  const { open, onClose, changeCookieConsentValue, onExit } = props;
  const [allowAnalytics, setAllowAnalytics] = useState<boolean>(true);
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();

  const handleCustomizeActions = (isCookiesAccepted: boolean) => {
    if (!isCookiesAccepted) {
      setAllowAnalytics(false);
    }
    changeCookieConsentValue(isCookiesAccepted, allowAnalytics);
  };

  useEffect(() => {
    const consentValue = getCookie(USER_COOKIE_CONSENT);
    setAllowAnalytics(consentValue === COOKIE_CONSENT_VALUE.granted);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onExit={onExit}
      anchor={isMobile ? 'bottom' : 'center'}
      styles={CustomizeCookiesModalStyles}
      header={
        <CustomizeCookiesModalHeader>
          <Typography
            className="customize-cookie-title"
            variant={isMobile ? 'title' : 'headline'}
            size={isMobile ? 'medium' : 'small'}>
            {t('Customize Cookies')}
          </Typography>
          <CustomizeModalCloseButton onClick={onClose} id="customize-cookie-modal-close-icon-btn">
            <CloseIcon size={16} color="gray" />
          </CustomizeModalCloseButton>
        </CustomizeCookiesModalHeader>
      }>
      <CustomizeCookiesModalContent>
        <Divider size={isMobile ? 40 : 24} />
        <div className="customize-cookie-item">
          <Typography variant="title" size="xmedium">
            {t('Strictly Necessary')}
          </Typography>
          <CheckboxStyle checked disabled />
        </div>

        <Divider size={isMobile ? 30 : 24} />
        <Typography
          className="customize-cookie-description"
          variant={isMobile ? 'body' : 'label'}
          size="large"
          color="$neutral700">
          {t(
            'These cookies are necessary to make the site work properly, and are always set then you visit the websites.',
          )}
        </Typography>
        <Divider size={10} />
        <CustomizeCookiesModalDivider />

        <Divider size={isMobile ? 40 : 36} />
        <div className="customize-cookie-item">
          <Typography variant="title" size="xmedium">
            {t('Analytics')}
          </Typography>
          <CheckboxStyle
            onCheckedChange={() => setAllowAnalytics((prev) => !prev)}
            checked={allowAnalytics}
          />
        </div>
        <Divider size={isMobile ? 30 : 24} />
        <Typography
          variant={isMobile ? 'body' : 'label'}
          className="customize-cookie-description"
          size="large"
          color="$neutral700">
          {t(
            'These cookies collect information to help us understand how the site is behind used. Vendors: Clarity, Google Analytics, Beamer.',
          )}
        </Typography>
        {isMobile && (
          <>
            <Divider size={10} />
            <CustomizeCookiesModalDivider />
          </>
        )}
      </CustomizeCookiesModalContent>
      <Divider size={isMobile ? 24 : 36} />
      <ButtonContainer>
        <Button
          onClick={() => handleCustomizeActions(false)}
          className="outline-button"
          id="customize-cookie-modal-allow-necessary-btn"
          type="primary"
          size="medium"
          variant="outlined">
          {t('Allow Necessary Cookies Only')}
        </Button>

        <Button
          onClick={() => handleCustomizeActions(true)}
          id="customize-cookie-modal-allow-necessary-btn"
          type="primary"
          size="medium"
          variant="contained">
          {t('Allow Accepted Cookies')}
        </Button>
      </ButtonContainer>
    </Modal>
  );
}
