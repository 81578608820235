import { darkTheme, Skeleton, styled } from '@rango-dev/ui';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link, {
  variants: {
    userRank: {
      '1': {
        '@sm': {
          padding: '$30 0 $20',
          order: 2,
        },
      },
      '2': {
        '@sm': {
          order: 1,
        },
      },
      '3': {
        '@sm': {
          order: 3,
        },
      },
    },
  },
});

export const Container = styled('div', {
  gap: '$10',
  display: 'flex',
  flexDirection: 'column',
  padding: '$0 $10',
  '@sm': {
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: '0 $15 $20',
    gap: '$10',
    flexDirection: 'row',
  },
});

export const Content = styled('div', {
  border: '1px solid $neutral400',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  borderRadius: '$xm',
  padding: '$10 $15',
  '&:hover': {
    borderColor: '$neutral500',
    [`.${darkTheme} &`]: {
      borderColor: '$neutral900',
    },
    '& .leaderboard-username': {
      textDecoration: 'underline',
    },
  },
  '@sm': {
    padding: '$20 0',
    width: 128,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  variants: {
    userRank: {
      '1': {
        '@sm': {
          padding: '$30 0 $20',
          order: 2,
        },
      },
      '2': {
        '@sm': {
          order: 1,
        },
      },
      '3': {
        '@sm': {
          order: 3,
        },
      },
    },
  },
});

export const UserImage = styled('img', {
  borderRadius: '100%',
  position: 'absolute',
  right: -23,
  borderWidth: '1px',
  borderStyle: 'solid',
  '@sm': {
    borderWidth: '3px',
    position: 'relative',
    right: 'unset',
  },

  variants: {
    userRank: {
      '1': {
        borderColor: '#F8D34C',
      },
      '2': {
        borderColor: '#DFE2E9',
      },
      '3': {
        borderColor: '#D8923C',
      },
    },
  },
});

export const TopUserRank = styled('img', {
  '@sm': {
    position: 'absolute',
    bottom: -20,
  },
});

export const UserImageContent = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@sm': {
    alignItems: 'end',
  },
});

export const ScoreContent = styled('div', {
  backgroundColor: '$warning100',
  width: '$24',
  height: '$24',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$lg',
});

export const TopUserRankSkeleton = styled(Skeleton, {
  '@sm': {
    position: 'absolute',
    bottom: -23,
  },
});

export const UserImageSkeleton = styled(Skeleton, {
  position: 'absolute',
  right: -23,
  '@sm': {
    position: 'relative',
    right: 'unset',
  },
});
