import { ReactElement } from 'react';
import {
  Container,
  FullLeaderboardButton,
  FullLeaderboardContent,
  Title,
} from './Leaderboard.styles';
import { Divider, ExternalLinkIcon } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { LeaderboardEmptyState } from './LeaderboardEmptyState';
import { PropTypes } from './Leaderboard.types';
import { TopUsers } from './TopUsers';
import { LoadingTopUsers } from './TopUsers/LoadingTopUsers';
import { List } from './List';
import { ListSkeleton } from './List/ListSkeleton';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function Leaderboard(props: PropTypes): ReactElement {
  const { t } = useTranslation();
  const { title = t('Leaderboard'), list, error, loading, isPublic = false } = props;
  const topUsers = isPublic ? list?.slice(0, 3) : [];
  const otherUsers = isPublic ? list?.slice(3) : list;
  const navigate = useNavigate();
  const { data: profileSummary } = useAppSelector((state) => state.profile.profileSummary);

  const renderLeaderboardContent = () => {
    if (error) {
      return <LeaderboardEmptyState />;
    }

    if (loading || !otherUsers) {
      return (
        <>
          {isPublic && <LoadingTopUsers />}
          <ListSkeleton maxLength={isPublic ? 7 : 11} />
        </>
      );
    }
    return (
      <>
        {isPublic && <TopUsers list={topUsers || []} />}
        <List list={otherUsers} userId={profileSummary?.id} />
      </>
    );
  };

  return (
    <Container>
      <Title variant="headline" size="xsmall">
        {title}
      </Title>
      <Divider size={'20'} />
      <div>{renderLeaderboardContent()}</div>
      <Divider size={20} />
      <FullLeaderboardContent>
        <FullLeaderboardButton
          onClick={() => navigate('/leaderboard')}
          id="profile-page-view-full-leaderboard"
          fullWidth
          variant="outlined"
          type="secondary"
          disabled={loading || !list}>
          <ExternalLinkIcon size={16} />
          {t('View Full Leaderboard')}
        </FullLeaderboardButton>
      </FullLeaderboardContent>
    </Container>
  );
}
