import { ReactElement } from 'react';
import { PropTypes } from './TopUserCard.types';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import {
  UserDetailsFooter,
  Container,
  UserRankHeader,
  UserImage,
  UserRank,
  CurrentUserTag,
} from './TopUserCard.styles';
import { Divider, ScoreIcon, Typography } from '@rango-dev/ui';
import { shortenUsername } from '../../Profile/Leaderboard/Leaderboard.helpers';
import { displayUserRank } from '../../Profile/ProfileSummary/ProfileSummary.helpers';
import { CenteredFlexBox } from '../../Profile/Leaderboard/List/List.styles';
import { ScoreContent } from '../../Profile/Leaderboard/TopUsers/TopUsers.styles';
import { getUserImageSize } from './TopUserCard.helpers';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Link } from 'react-router-dom';

export function TopUserCard(props: PropTypes): ReactElement {
  const { user } = props;
  const { isMobile } = useWindowDimensions();
  const isFirstUser = user.rank === 1;
  const { t } = useTranslation();
  const { data: profileDetails } = useAppSelector((state) => state.profile.profileDetails);

  const isCurrentUser = profileDetails?.id === user.id;

  return (
    <Container userRank={user.rank}>
      <Link id="leader-board-item-link" to={`../profile/${user.username}`}>
        <UserRankHeader className="user-rank-header">
          <div className="top-user-rank">
            <Typography size="large" variant={isFirstUser ? 'display' : 'headline'}>
              {displayUserRank(user.rank)}
            </Typography>
            <Typography size="medium" variant="label" color="neutral700">
              {t('Rank')}
            </Typography>
          </div>
          <div className="top-user-image">
            <UserImage
              userRank={user.rank}
              src={user.avatar}
              height={getUserImageSize(user.rank, isMobile)}
              width={getUserImageSize(user.rank, isMobile)}
            />
            <UserRank
              src={`/imgs/leaderboard/rank${user.rank}.svg`}
              alt={`Rank ${user.rank}`}
              width={32}
              height={32}
            />
          </div>
        </UserRankHeader>
        <UserDetailsFooter isCurrentUser={isCurrentUser} className="user-details">
          <CenteredFlexBox>
            <Typography
              className="leaderboard-username"
              variant={isMobile ? 'body' : 'title'}
              size={isMobile ? 'small' : 'xmedium'}>
              {shortenUsername({
                username: user.username,
                endChars: isMobile ? 4 : 5,
                startChars: isMobile ? 6 : 10,
              })}
            </Typography>
            {isCurrentUser && (
              <>
                <Divider direction="horizontal" size={10} />
                <CurrentUserTag className="current-user-tag">
                  <Typography color="secondary" size="medium" variant="label">
                    {t('You')}
                  </Typography>
                </CurrentUserTag>
              </>
            )}
          </CenteredFlexBox>

          <CenteredFlexBox>
            <ScoreContent>
              <ScoreIcon color="warning" size={16} />
            </ScoreContent>
            <Divider direction="horizontal" size={8} />
            <Typography variant="title" size={isMobile ? 'small' : 'xmedium'}>
              {displayUserRank(user.score)}
            </Typography>
          </CenteredFlexBox>
          {isCurrentUser && (
            <CurrentUserTag className="footer-current-user-tag">
              <Typography color="secondary" size="medium" variant="label">
                {t('You')}
              </Typography>
            </CurrentUserTag>
          )}
        </UserDetailsFooter>
      </Link>
    </Container>
  );
}
