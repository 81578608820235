import { darkTheme, Skeleton, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  borderRadius: '$xm',
  padding: '$15 $15 $20 $15',
  backgroundColor: '$background',
  minHeight: '100px',
  width: '100%',
  minWidth: '250px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  '@sm': {
    minHeight: '116px',
    minWidth: '300px',
    padding: '0 $25',
  },
  '@lg': {
    backgroundColor: '$neutral100',
  },
  '& .item-details': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'left',
  },
});

export const ItemValue = styled(Typography, {
  '@sm': {
    fontWeight: '$semiBold !important',
    fontSize: '$28 !important',
    lineHeight: '$36 !important',
  },
});

export const StyledSkeleton = styled(Skeleton, {
  '@sm': {
    height: '$32',
    borderRadius: '$md !important',
  },
});

export const StyledTypography = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});
