import { ReactElement } from 'react';
import { Divider, Skeleton } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { ListSkeletonPropTypes } from './List.types';
import { CenteredFlexBox, Content, Row } from './List.styles';

export function ListSkeleton(props: ListSkeletonPropTypes): ReactElement {
  const { isMobile } = useWindowDimensions();
  const { maxLength } = props;
  return (
    <div>
      {Array.from({ length: maxLength }).map((_, index) => (
        <Content key={index}>
          <Row>
            <CenteredFlexBox>
              <Skeleton height={24} variant="rounded" width={60} />

              <Divider size={isMobile ? 12 : 24} direction="horizontal" />
              <CenteredFlexBox>
                <Skeleton height={24} variant="circular" width={24} />
                <Divider size={isMobile ? 4 : 10} direction="horizontal" />
                <Skeleton
                  height={isMobile ? 16 : 24}
                  variant="rounded"
                  width={isMobile ? 77 : 130}
                />
              </CenteredFlexBox>
            </CenteredFlexBox>

            <Skeleton height={24} variant="rounded" width={60} />
          </Row>
        </Content>
      ))}
    </div>
  );
}
