import { Middleware } from '@reduxjs/toolkit';
import { RootActions, RootState } from './RootReducer';
import { loadState, saveState } from './helpers';
import {
  fetchProfileSummary,
  logOutUser,
  profileSliceInitialState,
  signMessage,
} from './ProfileSlice';
import {
  setHideSmallBalances,
  setHideEmptyWallets,
  setHideUnsupportedTokens,
  WalletDetailsOption,
  resetFilters,
  WALLET_DETAILS_LOCAL_STORAGE_KEY,
} from './WalletDetailsSlice';
import { ProfileSummary } from '../types';

const isWalletDetailsPersistActions = (action: any) => {
  if (
    action.type === setHideSmallBalances.type ||
    action.type === setHideEmptyWallets.type ||
    action.type === setHideUnsupportedTokens.type ||
    action.type === resetFilters.type
  ) {
    return true;
  }

  return false;
};

// Middleware to persist only WalletDetailsOption to localStorage
export const walletDetailsMiddleware: Middleware<object, RootState> =
  (storeAPI) => (next) => (action) => {
    const result = next(action);
    if (isWalletDetailsPersistActions(action)) {
      const state = storeAPI.getState().walletDetails;
      const walletDetailsOptions: WalletDetailsOption = {
        hideSmallBalances: state.hideSmallBalances,
        hideEmptyWallets: state.hideEmptyWallets,
        hideUnsupportedTokens: state.hideUnsupportedTokens,
      };
      localStorage.setItem(WALLET_DETAILS_LOCAL_STORAGE_KEY, JSON.stringify(walletDetailsOptions));
    }

    return result;
  };

export const AUTH_PERSIST_KEY = 'isLoggedIn';
export const PROFILE_PERSIST_KEY = 'profile';

export const loadPersistedState = (): Partial<RootState> => {
  const initialState = { ...profileSliceInitialState };
  const authState = loadState<boolean>(AUTH_PERSIST_KEY);
  const profileDataState = loadState<Partial<ProfileSummary>>(PROFILE_PERSIST_KEY);
  if (authState) {
    initialState.isLoggedIn = authState;
  }
  if (profileDataState?.username) {
    initialState.username = profileDataState.username;
  }
  return { profile: initialState };
};

export const persistProfileState: Middleware<object, RootState> =
  (store) => (next) => (action: RootActions) => {
    const prevState = store.getState();
    const returnValue = next(action);
    const nextState = store.getState();
    const shouldPersistAuthState = prevState.profile.isLoggedIn !== nextState.profile.isLoggedIn;
    const shouldPersistProfileData =
      (fetchProfileSummary.fulfilled.match(action) && !action.meta.public) ||
      signMessage.fulfilled.match(action) ||
      logOutUser.match(action);

    if (shouldPersistAuthState) {
      const state = store.getState().profile.isLoggedIn;
      saveState(AUTH_PERSIST_KEY, state);
    }

    if (shouldPersistProfileData) {
      const username = store.getState().profile.profileSummary.data?.username;
      saveState(PROFILE_PERSIST_KEY, { username });
    }

    return returnValue;
  };
