import { Divider, ShareIcon, TelegramIcon, Typography, XIcon } from '@rango-dev/ui';
import {
  ShareProgressSocialStyle,
  ShareSocialItemStyle,
  SocialDivider,
  SocialIconStyle,
  SocialLinkStyle,
} from './ShareProgress.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getProgressData, getSocialProgressText } from './ProfileProgress.helpers';
import { ShareSocialItemType } from './ShareProgress.types';
import { useTranslation } from 'react-i18next';

function ShareSocialItem(props: ShareSocialItemType) {
  const { t } = useTranslation();
  const { description, icon, link, title, id } = props;
  return (
    <ShareSocialItemStyle>
      <div className="social-info">
        {icon}
        <Divider size={'16'} direction="horizontal" />
        <div className="social-content">
          <Typography variant="title" size="medium">
            {title}
          </Typography>
          <Typography
            className="social-description"
            variant="body"
            size="medium"
            color="$neutral700">
            {description}
          </Typography>
        </div>
      </div>
      <SocialLinkStyle
        href={link}
        target="_blank"
        id={`profile-share-modal-${id}-link`}
        rel="noreferrer">
        <ShareIcon size={16} />
        <Divider size={'4'} direction="horizontal" />
        <Typography variant="title" size="small" color="$secondary">
          {t('Share')}
        </Typography>
      </SocialLinkStyle>
    </ShareSocialItemStyle>
  );
}

export function ShareProgressSocial() {
  const { t } = useTranslation();
  const profileSummary = useAppSelector((state) => state.profile.profileSummary.data);
  const progressData = getProgressData(profileSummary);
  const socialText = getSocialProgressText(progressData);

  const encodedLink = encodeURIComponent(
    `https://app.rango.exchange/profile/${profileSummary?.username}`,
  );

  const socialItems: ShareSocialItemType[] = [
    {
      title: 'X (Twitter)',
      id: 'twitter',
      description: t('Share your progress on {{socialName}}', { socialName: 'X' }),
      link: `https://twitter.com/intent/tweet?url=${encodedLink}&text=${encodeURIComponent(
        socialText,
      )}`,
      icon: (
        <SocialIconStyle className="twitter">
          <XIcon size={24} />
        </SocialIconStyle>
      ),
    },
    {
      title: 'Telegram',
      id: 'telegram',
      description: t('Share your progress on {{socialName}}', { socialName: 'Telegram' }),
      link: `https://t.me/share/url?text=${encodeURIComponent(socialText)}&url=${encodedLink}`,
      icon: (
        <SocialIconStyle className="telegram">
          <TelegramIcon color="white" size={24} />
        </SocialIconStyle>
      ),
    },
  ];

  return (
    <ShareProgressSocialStyle>
      <Typography className="share-title" variant="title" size="small" color="$neutral700">
        {t('Share on')}
      </Typography>
      {socialItems.map((socialItem, index) => (
        <>
          <ShareSocialItem key={socialItem.id} {...socialItem} />
          {socialItems.length - 1 !== index && <SocialDivider />}
        </>
      ))}
    </ShareProgressSocialStyle>
  );
}
