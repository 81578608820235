import { ToastProvider } from '@rango-dev/ui';
import './App.css';
import './reset.css';
import { AppRouter } from './AppRouter';
import { getContainer } from './utils/generalFunctions/common';
import { CookieConsentDialog } from './components/CookieConsent';

function App(): JSX.Element {
  return (
    <ToastProvider container={getContainer()}>
      <AppRouter />
      <CookieConsentDialog />
    </ToastProvider>
  );
}

export default App;
