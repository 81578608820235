import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootReducer';

const profileSelector = (state: RootState) => state.profile;
const usernameSelector = (_: unknown, username?: string) => username;

export const showPrivateProfileSelector = createSelector(
  [profileSelector, usernameSelector],
  (profile, username) => {
    return profile.isLoggedIn && profile.username === username;
  },
);
