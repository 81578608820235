import { numberToString } from '../../../utils/Numbers';

const USD_VALUE_DECIMALS = 1;
const USER_RANK_DECIMALS = 1;

export function displayUsdValue(value: number): string {
  return value === 0
    ? value.toString()
    : '$' + numberToString(value.toString(), USD_VALUE_DECIMALS);
}

export function displayUserRank(value: number): string | null {
  if (value < 0) {
    return null;
  }
  return value % 1 !== 0 ? value.toFixed(USER_RANK_DECIMALS) : value.toString();
}

export function displayHashtagContent(content: string) {
  const formattedString = content
    .split(/\s+/) // Split by spaces
    .map((word) =>
      /^[a-zA-Z]/.test(word) // Check if the first character is alphabetical
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word,
    ) // Capitalize if alphabetic
    .join(''); // Join without spaces

  return `#${formattedString}`;
}
