import { styled, darkTheme, Button } from '@rango-dev/ui';

export const Container = styled('div', {
  padding: '$15',
  paddingBottom: '$20',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'space-between',
  borderRadius: '$primary',
  background: 'radial-gradient(71.57% 100% at 50% 0%, #2C4899 0%, #131C49 100%)',
  border: '1px solid $secondary500',
  [`.${darkTheme} &`]: {
    borderColor: '$secondary250',
  },
  '@md': {
    padding: '$10 $30',
    flexDirection: 'row',
    alignItems: 'center',
  },
  variants: {
    isLoggedIn: {
      true: {
        '@md': {
          padding: '$30',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
  },
  '& .user-banner-text': {
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },
});

export const Info = styled('div', {
  padding: '0 $15',
  display: 'flex',
  flexDirection: 'column',
  '@md': {
    padding: '$10 0',
  },
});

export const Details = styled('div', {
  width: '100%',
  display: 'flex',
  '@md': {
    width: 'auto',
  },
});

export const LogInButton = styled(Button, {
  width: 150,
});

export const UserSummary = styled('div', {
  width: '100%',
  borderRadius: '$xm',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '$10',
  '@md': {
    width: 'auto',
    padding: '$15',
    border: '1px solid rgba(255, 255, 255, 0.15)',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.05) 100%)',
  },
});

export const RankContainer = styled('div', {
  padding: '$2 $10',
  borderRadius: '$primary',
  border: '1px solid rgba(255, 255, 255, 0.15)',
  background: 'rgba(255, 255, 255, 0.05)',
});

export const ScoreContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifySelf: 'end',
  gap: '$5',
  [`.${darkTheme} &`]: {
    '& svg': {
      color: 'background',
    },
  },
  '@md': {
    paddingLeft: '$60',
  },
});

export const Separator = styled('div', {
  width: '100%',
  borderBottom: '1px solid rgba(255, 255, 255, 0.15);',
  margin: '$15 0',
  '@md': {
    display: 'none',
  },
});

export const RankAndAvatar = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
