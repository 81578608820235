import { Button, darkTheme, styled, Typography } from '@rango-dev/ui';

export const WalletList = styled('ul', {
  overflowY: 'auto',
  marginBottom: '56px',
});

export const EmptyStateContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  paddingBottom: '$40',
  '@sm': {
    flex: 'unset',
    height: '290px',
  },
});

export const Footer = styled('div', {
  position: 'absolute',
  left: '$20',
  bottom: '$20',
  width: 'calc(100% - 40px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$background',
  paddingTop: '$15',
  '@sm': {
    padding: '$15 0 $15 0',
  },
});

export const StyledButton = styled(Button, {
  variants: {
    isMobile: {
      false: {
        height: '$26',
      },
    },
  },
  fontWeight: '$medium',
  '@sm': {
    [`.${darkTheme} &`]: {
      '&:not(:hover):not(:focus)': {
        color: '$neutral900',
      },
    },
  },
});

export const StyledTypography = styled(Typography, {
  color: 'inherit !important',
});
