import { EXPERIMENTAL_FEATURES, EXPERIMENTAL_FEATURES_LIST } from '../Constants';
import {
  ExperimentalEnvironment,
  ExperimentalFeatureName,
  ExperimentalFeature,
  TextFieldExperimentalFeature,
} from '../types';

function migrateExperimentalFeatures(
  experimentalFeature: ExperimentalFeature[],
): ExperimentalFeature[] {
  const updatedData: ExperimentalFeature[] = [];
  EXPERIMENTAL_FEATURES_LIST.forEach((feature) => {
    const featureAlreadyExistInStorage = experimentalFeature.find((f) => f.name === feature.name);
    if (featureAlreadyExistInStorage) {
      updatedData.push({ ...feature, ...featureAlreadyExistInStorage });
    } else {
      updatedData.push(feature);
    }
  });

  return updatedData;
}

export function getExperimentalFeatures(): ExperimentalFeature[] {
  const loadedData = localStorage.getItem('experimentalFeatures');
  return loadedData
    ? migrateExperimentalFeatures(JSON.parse(loadedData))
    : EXPERIMENTAL_FEATURES_LIST;
}

export function isAnyFeatureAvailableOnMode(): boolean {
  return (
    !!import.meta.env.REACT_APP_MODE &&
    getExperimentalFeatures().filter((f) =>
      f.availableOn.includes(import.meta.env.REACT_APP_MODE as ExperimentalEnvironment),
    )?.length > 0
  );
}

export function isFeatureAvailable(name: ExperimentalFeatureName): boolean {
  return (
    !!import.meta.env.REACT_APP_MODE &&
    getExperimentalFeatures()
      .filter((f) => f.name === name)?.[0]
      .availableOn?.includes(import.meta.env.REACT_APP_MODE as ExperimentalEnvironment)
  );
}

export function isFeatureEnabled(name: ExperimentalFeatureName): boolean {
  const feature = getExperimentalFeatures().filter((exp) => exp.name === name)?.[0];
  return isFeatureAvailable(name) && feature.type === 'switch' && feature.enable;
}

export function isEnabledCentralizedSwappers(): boolean {
  const isProductionMode =
    !!import.meta.env.REACT_APP_MODE && import.meta.env.REACT_APP_MODE === 'production';
  if (isProductionMode) {
    return true;
  }
  return isFeatureEnabled(ExperimentalFeatureName.CENTRALIZED_SWAPPERS);
}

export function updateExperimentalStatus(feature: ExperimentalFeature): void {
  const experimentalFeatures = getExperimentalFeatures();
  const updateExperimental = experimentalFeatures.map((f) => {
    return f.name === feature.name ? feature : f;
  });

  localStorage.setItem(EXPERIMENTAL_FEATURES, JSON.stringify(updateExperimental));
}

export function getExperimentalFeature(name: ExperimentalFeatureName): string | null {
  const apiKeyFeature = getExperimentalFeatures().find((feature) => feature.name === name) as
    | TextFieldExperimentalFeature
    | undefined;

  const featureAvailable = !!apiKeyFeature && isFeatureAvailable(apiKeyFeature?.name);

  return featureAvailable ? apiKeyFeature.value : null;
}
