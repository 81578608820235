import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  flex: 1,
  padding: '$10 $15',
  backgroundColor: '$neutral100',
  borderRadius: '$xm',
  display: 'flex',
  gap: '$8',
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  '& .value': {
    display: 'flex',
    alignItems: 'center',
    gap: '$5',
  },
});
