import { IconButton, Typography, css, darkTheme, styled } from '@rango-dev/ui';

export const linkStyles = css({});
export const circleStyles = css({
  backgroundColor: '$primary',
  [`.${darkTheme} &`]: {
    backgroundColor: '$secondary250',
  },
});
export const Links = styled('div', {
  display: 'flex',
  gap: '$50',
  alignItems: 'center',
  position: 'relative',
});

export const LinkContainer = styled('div', {
  position: 'relative',
  variants: {
    isActive: {
      true: {
        [`& .${linkStyles}`]: {
          $$color: '$colors$primary500',
          [`.${darkTheme} &`]: {
            $$color: '$colors$secondary250',
          },
          color: '$$color',
        },
      },
      false: {
        [`& .${linkStyles}`]: {
          $$color: '$colors$neutral700',
          [`.${darkTheme} &`]: {
            $$color: '$colors$neutral900',
          },
          '&:hover': {
            $$color: '$colors$info500',
            [`.${darkTheme} &`]: {
              $$color: '$colors$secondary250',
            },
          },
          color: '$$color',
        },
      },
    },
  },
});

export const BadgeContainer = styled('div', {
  position: 'absolute',
  top: '-$15',
  right: '-$32',
});

export const NewBadge = styled(Typography, {
  padding: '$2 $6',
  lineHeight: '$12 !important',
  backgroundColor: '$error500',
  minWidth: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$md',

  [`.${darkTheme} &`]: {
    backgroundColor: '$error500',
    color: '$foreground',
  },
});

export const DialogContainer = styled('div', {
  padding: '$20',
  borderRadius: '$10',
  gap: '$15',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
});

export const DialogContent = styled('div', {
  gap: '$25',
  display: 'flex',
  flexDirection: 'column',
});

export const MoreLink = styled('div', {
  display: 'flex',
  gap: '$5',
  alignItems: 'center',
  '& svg': {
    color: '$foreground',
  },
  '&:hover': {
    '& ._typography, & svg': {
      color: '$secondary500',
    },
  },
});

export const Icon = styled(IconButton, {
  '& svg': {
    color: '$neutral700',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
  '&:hover': {
    '& svg': {
      color: '$secondary500',
      [`.${darkTheme} &`]: {
        color: '$foreground',
      },
    },
  },
  variants: {
    open: {
      true: {
        '& svg': {
          color: '$primary500',
          [`.${darkTheme} &`]: {
            color: '$secondary250',
          },
        },
      },
    },
  },
});
