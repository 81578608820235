import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../reduxHooks';
import { logInUser, logOutUser } from '../../state/ProfileSlice';
import { AUTH_PERSIST_KEY } from '../../state/Middlewares';

// This hook is used to synchronize the authentication state across multiple tabs.
export function useSyncStoreWithLocalStorage() {
  const isUserLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    /**
     * When the authentication state changes in one tab,
     * the storage event is triggered, and the handler updates the Redux store correctly in the other open tabs.
     */
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === AUTH_PERSIST_KEY) {
        const isAuthenticated = event.newValue === 'true';

        if (isAuthenticated) {
          dispatch(logInUser());
        } else {
          dispatch(logOutUser());
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, isUserLoggedIn]);
}
