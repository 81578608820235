import { Token } from 'rango-sdk';
import { RANGO_CONTAINER_ID } from '../../constants/theme';
import { Languages } from '../../constants/i18n';
import {
  COOKIE_CONSENT_VALUE,
  COOKIE_DOMAIN,
  DAY_IN_SECOND,
  GTM_COOKIE_NAME,
} from '../../constants/common';
import { GTMEvents } from '../../constants/events';

export function normalizeWith<
  T extends Array<R>,
  R = Record<string, string | number | boolean | object | unknown[] | null>,
>(array: T, key: string): { [key: string]: R } {
  function assignBy(key: string) {
    return (data: { [key: string]: R }, item: R) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (item as any)[key];
      if (typeof value === 'string' || typeof value === 'number') {
        data[value] = item;
      }
      return data;
    };
  }

  return array.reduce(assignBy(key), {});
}

export const handlePreviousURLs = (inputUrl: string) => {
  const matchUrl = inputUrl.replace(/\/$/, '').match(/\/swap\/(.+)\/(.+)/);
  if (matchUrl) {
    const [from, to] = matchUrl?.slice(1);
    const [fromBlockchain, fromToken] = from.split('.');
    const [toBlockchain, toToken] = to.split('.');

    return {
      fromBlockchain,
      fromToken,
      toBlockchain,
      toToken,
    };
  }
  return null;
};

export function areTokensEqual(
  tokenA: Pick<Token, 'blockchain' | 'symbol' | 'address'> | null,
  tokenB: Pick<Token, 'blockchain' | 'symbol' | 'address'> | null,
) {
  return (
    tokenA?.blockchain === tokenB?.blockchain &&
    tokenA?.symbol === tokenB?.symbol &&
    tokenA?.address === tokenB?.address
  );
}

export const getContainer = () => document.getElementById(RANGO_CONTAINER_ID) as HTMLDivElement;

export const detectOSDarkMode = () => {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
};

export const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export const stringArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const navigator: any;
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
export function hasTouchScreen() {
  let isMobile = false;
  if ('maxTouchPoints' in navigator) {
    isMobile = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    isMobile = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') {
      isMobile = !!mQ.matches;
    } else if ('orientation' in window) {
      isMobile = true;
    } else {
      const UA = navigator.userAgent;
      isMobile =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return isMobile;
}

export function capitalizeFirstLetter(text: string) {
  return text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1);
}

export function retry(
  fn: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>;
  }>,
  retriesLeft = 4,
  interval = 1000,
) {
  return new Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>;
  }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: unknown) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function isValidLocale(locale: string) {
  return Languages.some((languageItem) => languageItem.value === locale);
}

export function getCookie(name: string) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match && match.length >= 2 ? match[2] : null;
}

export function setCookie(name: string, value: string, days: number, domain = COOKIE_DOMAIN) {
  const maxAge = DAY_IN_SECOND * days;
  document.cookie = `${name}=${value};Max-Age=${maxAge};SameSite=Strict;Secure;domain=${domain};path=/`;
}

export function updateCookieConsent(isGranted: boolean) {
  window.gtag('consent', 'update', {
    ad_user_data: isGranted ? COOKIE_CONSENT_VALUE.granted : COOKIE_CONSENT_VALUE.denied,
    ad_personalization: isGranted ? COOKIE_CONSENT_VALUE.granted : COOKIE_CONSENT_VALUE.denied,
    ad_storage: isGranted ? COOKIE_CONSENT_VALUE.granted : COOKIE_CONSENT_VALUE.denied,
    analytics_storage: isGranted ? COOKIE_CONSENT_VALUE.granted : COOKIE_CONSENT_VALUE.denied,
  });

  if (isGranted) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: GTMEvents.CHANGE_CONSENT,
    });
  }
}

const isGoogleTagManagerCookie = (name: string) =>
  GTM_COOKIE_NAME.some((GTMItem) => name.includes(GTMItem.value));

export const removeGTMCookies = async () => {
  if (window.cookieStore) {
    window.cookieStore?.getAll().then((data: any) => {
      data.forEach((cookie: any) => {
        if (isGoogleTagManagerCookie(cookie.name)) {
          window.cookieStore.delete(cookie);
        }
      });
    });
  } else {
    // for some browsers that may not support cookieStore
    const cookies = document.cookie.split(';');
    const cookieDomain = location.hostname;
    let updatedCookies = '';

    let preservedCookies = '';

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      if (isGoogleTagManagerCookie(cookie)) {
        const parts = cookie.split('=');
        const cookieName = parts.shift();
        updatedCookies +=
          cookieName + `=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${cookieDomain}; `;
      } else {
        preservedCookies += cookie.trim() + '; ';
      }
    }

    updatedCookies += preservedCookies.trim();
    document.cookie = updatedCookies;
  }
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
