import { darkTheme, Skeleton, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  borderRadius: '$xm',
  padding: '$15',
  backgroundColor: '$background',
  height: '218px',
  minWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@sm': {
    height: '251px',
    minWidth: '300px',
    padding: '$30 $25',
    flex: 1,
  },
  '@lg': {
    backgroundColor: '$neutral100',
  },
  '& .total': {
    paddingBottom: '$10',
    '& svg': { width: '18px', height: '18px' },
    '@sm': {
      paddingBottom: '$20',
    },
    width: '100%',
    borderBottom: '0.5px solid $neutral400',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [`.${darkTheme} &`]: {
      borderColor: '$neutral900',
    },
  },
  '& .effective': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '$26',
    '@sm': {
      height: '$28',
    },
    '& .title': {
      maxWidth: '200px',
    },
  },
  '& .title': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    '& svg': { width: '18px', height: '18px' },
  },
});

export const Title = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900 !important',
  },
});

export const TotalValueSkeleton = styled(Skeleton, {
  borderRadius: '999px !important',
});

export const EffectiveValueSkeleton = styled(Skeleton, {
  '@sm': {
    height: '$28',
  },
});
