import { Divider } from '@rango-dev/ui';
import { ProfileCard } from '../ProfileCard';
import { SidebarContainer } from './LoggedInUserSidebar.styles';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';
import { Leaderboard } from '../Leaderboard';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function LoggedInUserSidebar() {
  const {
    data: leaderboard,
    loading: leaderboardLoading,
    error: leaderboardError,
  } = useAppSelector((state) => state.profile.profileLeaderboard);
  return (
    <SidebarContainer>
      <ProfileCard />
      <Divider size={24} />
      <Leaderboard error={leaderboardError} loading={leaderboardLoading} list={leaderboard} />
      <Divider direction="vertical" size={24} />
      <Campaigns />
    </SidebarContainer>
  );
}
