import { Image, Button, darkTheme, styled, Typography, rangoDarkColors } from '@rango-dev/ui';

export const Container = styled('div', {
  borderRadius: '$xm',
  padding: '$20 0',
  backgroundColor: '$background',
  width: '100%',
  minWidth: '250px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  '@sm': {
    height: '116px',
    minWidth: '300px',
    padding: '35px 0',
  },
  [`.${darkTheme} &`]: {
    backgroundColor: '$background',
  },
});

export const Header = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$15',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 $20',
  '@sm': {
    padding: '0 35px',
  },
  '& .header-info': {
    display: 'flex',
    alignItems: 'center',
    gap: '$15',
    '& .title': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      maxWidth: 140,
    },
  },
  '& .header-buttons': {
    display: 'flex',
    gap: '$15',
  },
});

export const UserAvatar = styled(Image, {
  borderRadius: '100%',
});

export const Content = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$15',
  padding: '$30 $20 0',
  '@sm': {
    padding: '$30 35px 0',
  },
});

export const Footer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '$20 $20 0',
  borderTop: '1px solid $neutral300',
  [`.${darkTheme} &`]: {
    borderColor: '$neutral400',
  },
  gap: '$10',
  '@sm': {
    flexDirection: 'row',
    gap: '$15',
    padding: '$20 35px 0',
  },
});

export const FooterButton = styled(Button, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .button-content': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '$8',
  },
});

export const ShareButton = styled(FooterButton, {
  '& svg': { transition: 'all 0.35s' },
  '&:hover svg, &:focus svg': {
    color: '$secondary550',
    [`.${darkTheme} &`]: {
      color: rangoDarkColors.secondary800,
    },
  },
});

export const ProfileId = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});

export const UserScoreProgressContent = styled('div', {
  padding: '$15 $20 $15',
  width: '100%',
  '@sm': {
    padding: '$30 35px $15',
  },
});
