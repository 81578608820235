import { ReactElement } from 'react';
import { Divider, Image, Typography } from '@rango-dev/ui';
import { AlignedFlexBox, FavoriteItemsWrapper } from './ProfileFavorites.styles';
import { PropTypes } from './FavoriteItem.types';

export function FavoriteItem(props: PropTypes): ReactElement {
  const { logo, name, title } = props;
  return (
    <FavoriteItemsWrapper>
      <Typography className="favorite-item-title" size="small" variant="body">
        {title}
      </Typography>
      <AlignedFlexBox>
        <Image src={logo} size={16} />
        <Divider direction="horizontal" size={4} />
        <Typography size="xmedium" variant="title">
          {name}
        </Typography>
      </AlignedFlexBox>
    </FavoriteItemsWrapper>
  );
}
