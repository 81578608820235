import { darkTheme, IconButton, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const LogOutButton = styled(IconButton, {
  width: '$32',
  height: '$32',
  '&:disabled': {
    [`.${darkTheme} &`]: {
      color: '$neutral700',
    },
    '& svg': {
      color: '$neutral700',
      [`.${darkTheme} &`]: {
        color: '$foreground',
      },
    },
  },
});
