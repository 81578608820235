import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectWallet } from '../ConnectWallet';
import { useLocation } from 'react-router-dom';
import {
  MenuIcon,
  IconButton,
  SwapIcon,
  BugReportIcon,
  ScannerIcon,
  LegacyIcon,
  FeatureListIcon,
  CustomerSupportIcon,
  DocumentationIcon,
  ProfileIcon,
  AffiliateIcon,
  NumberBlocksIcon,
} from '@rango-dev/ui';
import { NavLinks } from '../NavLinks';
import { Notifications } from '../Notifications';
import { Logo } from '../Logo';
import { SideMenu } from '../SideMenu';
import { BodyContainer, HeaderContainer, LayoutContainer, MainContainer } from './Layout.styles';
import { Settings, WhatsNew } from '../Settings';
import { RANGO_CONTAINER_ID } from '../../constants/theme';
import { WIDGET_BASE_ROUTE } from '../../constants/navigation';
import { MobileMenu } from '../MobileMenu';
import { useAppSelector } from '../../hooks/reduxHooks';
import { DEFAULT_LANGUAGE } from '../../constants/i18n';
import { NavLinkType } from '../NavLinks/NavLink.types';

export const Layout: FunctionComponent<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.settings);

  const links: NavLinkType[] = [
    {
      location:
        language === DEFAULT_LANGUAGE ? WIDGET_BASE_ROUTE : `${language}${WIDGET_BASE_ROUTE}`,
      id: 'menu-swap-link',
      title: t('Swap'),
      icon: <SwapIcon color="gray" size={24} />,
    },
    {
      location: language === DEFAULT_LANGUAGE ? '/profile' : `/${language}/profile`,
      id: 'menu-profile-link',
      title: t('Profile'),
      icon: <ProfileIcon color="gray" size={24} />,
    },
    {
      location: language === DEFAULT_LANGUAGE ? '/leaderboard' : `/${language}/leaderboard`,
      id: 'menu-leaderboard-link',
      title: t('Leaderboard'),
      icon: <NumberBlocksIcon color="gray" size={24} />,
    },
    {
      location: language === DEFAULT_LANGUAGE ? '/affiliate' : `/${language}/affiliate`,
      id: 'menu-affiliate-link',
      title: t('Affiliate'),
      icon: <AffiliateIcon color="gray" size={24} />,
    },
    {
      location: 'https://discord.gg/q3EngGyTrZ',
      id: 'menu-support-link',
      title: t('Support'),
      isAdditionalLink: true,
      icon: <CustomerSupportIcon />,
    },
    {
      location: 'https://docs.rango.exchange',
      id: 'menu-documentation-link',
      title: t('Documentation'),
      isAdditionalLink: true,
      icon: <DocumentationIcon />,
    },
    {
      location: 'https://updates.rango.exchange/ideas/en',
      id: 'menu-feature-request-link',
      title: t('Feature Request'),
      isAdditionalLink: true,
      icon: <FeatureListIcon />,
    },
    {
      location: 'https://docs.rango.exchange/bug-bounty',
      id: 'menu-bug-report-link',
      title: t('Bug Report'),
      isAdditionalLink: true,
      icon: <BugReportIcon />,
    },
    {
      location: 'https://explorer.rango.exchange',
      id: 'menu-rango-explorer-link',
      title: t('Rango Explorer'),
      isAdditionalLink: true,
      icon: <ScannerIcon />,
    },
    {
      location: 'https://app-legacy.rango.exchange',
      id: 'menu-legacy-app-link',
      title: t('Legacy App'),
      isAdditionalLink: true,
      icon: <LegacyIcon />,
    },
  ];

  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const toggleMenu = () => setShowMenu((prevState) => !prevState);
  const { pathname } = useLocation();

  useEffect(() => {
    if (showMenu) {
      document.documentElement.style.overflow = 'hidden';
    }
    return () => {
      document.documentElement.style.overflow = 'unset';
    };
  }, [showMenu]);

  useEffect(() => {
    if (showMenu) toggleMenu();
  }, [pathname]);

  return (
    <LayoutContainer id={RANGO_CONTAINER_ID}>
      <BodyContainer>
        <HeaderContainer>
          <div className="flex items-center gap-x-20">
            <div className="w-auto">
              <Logo />
            </div>
            <div className="relative hidden flex-grow flex-row flex-wrap justify-center gap-x-14 text-center lg:justify-start lg:text-left xl:flex">
              <NavLinks links={links} />
            </div>
          </div>
          <div className="ml-auto flex justify-center">
            <div className="flex flex-wrap items-center justify-center">
              <WhatsNew />
              <Settings />
              <Notifications />
              <div className="flex items-center justify-center">
                <ConnectWallet />
              </div>
            </div>
          </div>
          <div className="relative hidden items-center sm:flex md:ml-4 xl:hidden">
            <span className="new-badge-circle-container">
              <span className="new-badge-circle" />
            </span>
            <IconButton id="header-menu-icon-btn" onClick={toggleMenu} variant="ghost">
              <MenuIcon color="black" size={24} />
            </IconButton>
            <SideMenu open={showMenu} onClose={toggleMenu} links={links} />
          </div>
        </HeaderContainer>
        <MainContainer>{props.children}</MainContainer>
      </BodyContainer>
      <MobileMenu links={links} />
    </LayoutContainer>
  );
};
