import { ReactElement, useEffect, useRef, useState } from 'react';

import { Divider, Image, ScoreIcon, Typography } from '@rango-dev/ui';
import { getUserImageSize, shortenUsername } from '../Leaderboard.helpers';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { displayUserRank } from '../../ProfileSummary/ProfileSummary.helpers';
import { PropTypes } from './List.types';
import { CenteredFlexBox, Content, Rank, Row, Score, UserNameContent } from './List.styles';
import { CurrentUserTag } from '../../../Leaderboard/TopUserCard/TopUserCard.styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function List(props: PropTypes): ReactElement {
  const { list, itemClassName, showCurrentUserTag = false, userId } = props;

  const { isMobile } = useWindowDimensions();
  const [isSmall, setIsSmall] = useState(true);
  const itemRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect.width < 650) {
          setIsSmall(true);
        } else {
          setIsSmall(false);
        }
      }
    });

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, []);

  return (
    <>
      {list.map((item) => {
        const isCurrentUser = userId === item.id;
        return (
          <Content
            ref={itemRef}
            className={`${itemClassName} ${isCurrentUser && 'current-user-item'}`}
            key={item.id}>
            <Link id="leader-board-item-link" to={`../profile/${item.username}`}>
              <Row className="user-item-row">
                <CenteredFlexBox>
                  <Rank className="user-item-rank" variant="title" size="small">
                    {displayUserRank(item.rank)}
                  </Rank>

                  <Divider size={isMobile ? 12 : 24} direction="horizontal" />
                  <CenteredFlexBox>
                    <Image
                      src={item.avatar}
                      size={showCurrentUserTag ? 24 : getUserImageSize(isCurrentUser, isMobile)}
                    />
                    <Divider size={isMobile ? 4 : 10} direction="horizontal" />
                    <UserNameContent>
                      <Typography
                        className="leaderboard-username"
                        variant="body"
                        size={isMobile ? 'small' : 'medium'}>
                        {isSmall ? shortenUsername({ username: item.username }) : item.username}
                      </Typography>
                      {showCurrentUserTag && isCurrentUser && (
                        <CurrentUserTag>
                          <Typography color="secondary" size="medium" variant="label">
                            {t('You')}
                          </Typography>
                        </CurrentUserTag>
                      )}
                    </UserNameContent>
                  </CenteredFlexBox>
                </CenteredFlexBox>

                <CenteredFlexBox>
                  <ScoreIcon color="gray" size={20} />
                  <Divider size={4} direction="horizontal" />
                  <Score variant="body" size={isMobile ? 'small' : 'medium'} color="neutral700">
                    {displayUserRank(item.score)}
                  </Score>
                </CenteredFlexBox>
              </Row>
            </Link>
          </Content>
        );
      })}
    </>
  );
}
