import { darkTheme, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  textAlign: 'left',
  maxWidth: 1150,
  margin: '$0 auto',
  padding: '$10 0 $32',
  width: '100%',
  '@sm': {
    padding: '$10 $20',
  },
  '@md': {
    padding: '$100 $20',
  },
});

export const Title = styled('span', {
  fontSize: '$28',
  fontWeight: '$semiBold',
  color: '$foreground',
  '@md': {
    fontSize: 57,
    fontWeight: '$semiBold',
  },
});

export const SubTitle = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});
export const ListWrapper = styled('div', {
  backgroundColor: '$background',
  borderRadius: '$primary',
  overflow: 'hidden',
  '& .user-item-rank': {
    [`.${darkTheme} &`]: {
      backgroundColor: '$secondary550',
    },
  },
  '& .leaderboard-item': {
    borderBottom: 'none',
    '&:nth-child(odd)': {
      backgroundColor: '$neutral100',
    },
    '&:hover': {
      backgroundColor: '$secondary100',
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral500',
      },
    },
  },
});

export const TopUserContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  gap: '$5',
  '@sm': {
    gap: '$15',
  },
});
