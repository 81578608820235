import { ProfileSummaryItemsContainer } from './ProfileSummaryMobile.styles';
import { ProfileSummaryItems } from '../ProfileSummaryItems';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';
import { ProfileFavorites } from '../ProfileFavorites';
import { ProfileCard } from '../ProfileCard';

export function ProfileSummaryMobile() {
  const { loading, error } = useAppSelector((state) => state.profile.profileSummary);
  const showError = !loading && !!error;

  return (
    <ProfileSummaryItemsContainer>
      {!showError && (
        <>
          <ProfileCard />
          <ProfileSummaryItems />
          <ProfileFavorites />
        </>
      )}
      <Campaigns />
    </ProfileSummaryItemsContainer>
  );
}
