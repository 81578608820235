import {
  Container,
  ProgressBar,
  ProgressBarContainer,
  Score,
  ScoreContent,
} from './UserScoreProgress.styles';
import { useEffect, useState } from 'react';
import { ScoreIcon, Skeleton, Typography } from '@rango-dev/ui';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { displayUserRank } from '../ProfileSummary/ProfileSummary.helpers';

export function UserScoreProgress() {
  const [progress, setProgress] = useState(0);
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);

  useEffect(() => {
    if (profileSummary) {
      const levelProgress = profileSummary.levelProgress === 0 ? 3 : profileSummary.levelProgress;
      const timeout = setTimeout(() => {
        setProgress(levelProgress);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [profileSummary?.levelProgress]);

  return (
    <Container>
      {loading ? (
        <Skeleton variant="rounded" height={10} />
      ) : (
        <ProgressBarContainer>
          <ProgressBar style={{ width: `${progress}%` }} />
        </ProgressBarContainer>
      )}

      <div className="level-score-content">
        {loading ? (
          <>
            <Skeleton variant="rounded" height={24} width={60} />
            <Skeleton variant="rounded" height={24} width={60} />
          </>
        ) : (
          <>
            <Typography size="xmedium" variant="title">
              Lvl - {profileSummary?.level.level}
            </Typography>
            <ScoreContent>
              <ScoreIcon color="gray" size={20} />
              {profileSummary && (
                <Score size="small" variant="title" color="neutral700">
                  {displayUserRank(profileSummary.score)} / {profileSummary.level.to}
                </Score>
              )}
            </ScoreContent>
          </>
        )}
      </div>
    </Container>
  );
}
