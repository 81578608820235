import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Typography } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Container } from './UserProfileNotFound.styles';
import { PropTypes } from './UserProfileNotFound.types';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function UserProfileNotFound(props: PropTypes) {
  const { username } = props;
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToHome = () => navigate('/');

  return (
    <Container>
      <img
        className="profile-not-found-image"
        src={`/imgs/profile/profile-not-found-${isDarkMode ? 'dark' : 'light'}.png`}
        alt="profile not found"
        width={isMobile ? 198 : 428}
        height={isMobile ? 148 : 318}
      />
      <Typography
        className="title"
        variant={isMobile ? 'title' : 'display'}
        size={isMobile ? 'xmedium' : 'medium'}>
        {t('Oops! User profile not found')}
      </Typography>
      <Divider size={10} />
      <Typography
        className="description"
        variant={isMobile ? 'body' : 'headline'}
        size={isMobile ? 'medium' : 'xsmall'}
        color="neutral600">
        {t('The profile URL you entered does not exist: ')}
        {isMobile && <br />}
        {username}
        <br />
        {t('Please check the URL and try again.')}
      </Typography>
      <Button
        id="profile-not-found-back-to-home-button"
        className="back-to-home"
        variant="contained"
        type="primary"
        size={isMobile ? 'medium' : 'large'}
        onClick={navigateToHome}>
        {t('Back to home')}
      </Button>
    </Container>
  );
}
