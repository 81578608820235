import { AxiosRequestConfig } from 'axios';
import { httpService } from '../services';
import {
  LoginMessageRequest,
  LoginMessageResponse,
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  ProfileDetailsResponse,
  MergeMessageRequest,
  MergeMessageResponse,
  MergeRequest,
  MergeResponse,
  ProfileSummaryResponse,
  ProfileLeaderboardResponse,
  PublicLeaderboardRequest,
} from '../types';

export const BASE_PROFILE_URL = 'profile';

const requestConfig: AxiosRequestConfig = { withCredentials: true };

export async function getLoginMessage(
  body: LoginMessageRequest,
  signal?: AbortSignal,
): Promise<LoginMessageResponse> {
  const { data } = await httpService.post<LoginMessageResponse>(
    `${BASE_PROFILE_URL}/login-message`,
    body,
    { ...requestConfig, signal },
  );

  return data;
}

export async function logIn(body: LoginRequest, signal?: AbortSignal): Promise<LoginResponse> {
  const { data } = await httpService.post<LoginResponse>(`${BASE_PROFILE_URL}/login`, body, {
    ...requestConfig,
    signal,
  });

  return data;
}

export async function logOut(signal?: AbortSignal): Promise<LogoutResponse> {
  const { data } = await httpService.get<LogoutResponse>(`${BASE_PROFILE_URL}/logout`, {
    ...requestConfig,
    signal,
  });

  return data;
}

export async function getProfileSummary(signal?: AbortSignal): Promise<ProfileSummaryResponse> {
  const { data } = await httpService.get<ProfileSummaryResponse>(`${BASE_PROFILE_URL}/summary`, {
    ...requestConfig,
    signal,
  });

  return data;
}

export async function getPublicProfileSummary(
  username: string,
  signal?: AbortSignal,
): Promise<ProfileSummaryResponse> {
  const { data } = await httpService.get<ProfileSummaryResponse>(
    `${BASE_PROFILE_URL}/public/${username}/summary`,
    {
      ...requestConfig,
      signal,
    },
  );
  return data;
}

export async function getProfileLeaderboard(
  signal?: AbortSignal,
): Promise<ProfileLeaderboardResponse> {
  const { data } = await httpService.get<ProfileLeaderboardResponse>(
    `${BASE_PROFILE_URL}/user-leaderboard`,
    {
      ...requestConfig,
      signal,
    },
  );

  return data;
}

export async function getPublicLeaderboard(
  params: PublicLeaderboardRequest,
  signal?: AbortSignal,
): Promise<ProfileLeaderboardResponse> {
  const { data } = await httpService.get<ProfileLeaderboardResponse>(
    `${BASE_PROFILE_URL}/public/leaderboard`,
    {
      ...requestConfig,
      signal,
      params,
    },
  );

  return data;
}

export async function getPublicProfileLeaderboard(
  username: string,
  signal?: AbortSignal,
): Promise<ProfileLeaderboardResponse> {
  const { data } = await httpService.get<ProfileLeaderboardResponse>(
    `${BASE_PROFILE_URL}/public/${username}/user-leaderboard`,
    {
      ...requestConfig,
      signal,
    },
  );

  return data;
}

export async function getProfileDetails(signal?: AbortSignal): Promise<ProfileDetailsResponse> {
  const { data } = await httpService.get<ProfileDetailsResponse>(`${BASE_PROFILE_URL}/details`, {
    ...requestConfig,
    signal,
  });

  return data;
}

export async function getMergeAccountMessage(
  body: MergeMessageRequest,
  signal?: AbortSignal,
): Promise<MergeMessageResponse> {
  const { data } = await httpService.post(`${BASE_PROFILE_URL}/merge-request`, body, {
    ...requestConfig,
    signal,
  });

  return data;
}

export async function mergeAccount(
  body: MergeRequest,
  signal?: AbortSignal,
): Promise<MergeResponse> {
  const { data } = await httpService.post<MergeResponse>(`${BASE_PROFILE_URL}/merge`, body, {
    ...requestConfig,
    signal,
  });

  return data;
}
