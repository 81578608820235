import { Trans, useTranslation } from 'react-i18next';
import { CloseButton, CookieConsentDialogContainer } from './CookieConsent.styles';
import { Button, CloseIcon, Divider, Typography } from '@rango-dev/ui';
import { useEffect, useState } from 'react';
import { CustomizeCookiesModal } from './CustomizeCookiesModal';
import {
  getCookie,
  removeGTMCookies,
  setCookie,
  updateCookieConsent,
} from '../../utils/generalFunctions/common';
import {
  COOKIE_CONSENT_VALUE,
  USER_COOKIE_CONSENT,
  USER_COOKIE_CONSENT_EXPIRATION_DAYS,
} from '../../constants/common';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { updateShowCookieConsentDialog } from '../../state/SettingsSlice';

export function CookieConsentDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const showCookieConsentDialog = useAppSelector((state) => state.settings.showCookieConsentDialog);
  const [openCustomizeModal, setOpenCustomizeModal] = useState<boolean>(false);
  const [hasOnExit, setHasOnExit] = useState<boolean>(true);

  useEffect(() => {
    const consentValue = getCookie(USER_COOKIE_CONSENT);
    if (!consentValue) {
      dispatch(updateShowCookieConsentDialog(true));
    }
    updateCookieConsent(consentValue === COOKIE_CONSENT_VALUE.granted);
  }, []);

  const handleCustomizeModalClose = () => {
    setHasOnExit(true);
    setOpenCustomizeModal(false);
  };

  const handleOnExit = () => {
    if (hasOnExit) {
      dispatch(updateShowCookieConsentDialog(true));
    }
  };

  const handleCustomizeModalOpen = () => {
    setOpenCustomizeModal(true);
    dispatch(updateShowCookieConsentDialog(false));
  };

  const changeCookieConsentValue = (isCookiesAccepted: boolean, allowAnalytics = true) => {
    const isGranted = isCookiesAccepted && allowAnalytics;
    updateCookieConsent(isGranted);
    const consentValue = isGranted ? COOKIE_CONSENT_VALUE.granted : COOKIE_CONSENT_VALUE.denied;
    setCookie(USER_COOKIE_CONSENT, consentValue, USER_COOKIE_CONSENT_EXPIRATION_DAYS);
    if (!isGranted) {
      removeGTMCookies();
    }
    setHasOnExit(false);
    setOpenCustomizeModal(false);
    dispatch(updateShowCookieConsentDialog(false));
  };

  return (
    <CookieConsentDialogContainer open={showCookieConsentDialog}>
      <div className="cookie-content">
        <CloseButton onClick={() => dispatch(updateShowCookieConsentDialog(false))}>
          <CloseIcon size={10} color="gray" />
        </CloseButton>
        <Typography className="cookie-title" variant="title" size="medium">
          {t('Cookies and privacy')}
        </Typography>
        <Divider size={16} />
        <Typography className="cookie-description" color="$neutral600" variant="body" size="large">
          <Trans i18nKey="In order to deliver the best experience, we use cookies and log user data. By using  Rango, you agree to our Privacy Policy and Terms of Use.">
            In order to deliver the best experience, we use cookies and log user data. By using
            Rango, you agree to our
            <a
              className="cookie-link"
              href="https://docs.rango.exchange/privacy-policy"
              target="_blank"
              rel="noreferrer">
              Privacy Policy
            </a>
            and
            <a
              className="cookie-link"
              href="https://docs.rango.exchange/terms-of-use"
              target="_blank"
              rel="noreferrer">
              Terms of Use
            </a>
            .
          </Trans>
        </Typography>
        <Divider size={24} />
        <Button
          onClick={() => changeCookieConsentValue(true)}
          className="cookie-button"
          id="cookie-consent-dialog-accept-btn"
          type="primary"
          variant="contained"
          size="small"
          fullWidth>
          {t('Accept')}
        </Button>
        <Divider size={16} />
        <Button
          onClick={() => changeCookieConsentValue(false)}
          className="cookie-button outline-button"
          id="cookie-consent-dialog-decline-optional-btn"
          type="secondary"
          variant="outlined"
          size="small"
          fullWidth>
          {t('Decline Optional Cookies')}
        </Button>
        <Divider size={16} />
        <Button
          onClick={handleCustomizeModalOpen}
          className="cookie-button outline-button"
          id="cookie-consent-dialog-customize-btn"
          type="secondary"
          variant="outlined"
          size="small"
          fullWidth>
          {t('Customize')}
        </Button>
      </div>
      <CustomizeCookiesModal
        changeCookieConsentValue={changeCookieConsentValue}
        open={openCustomizeModal}
        onClose={handleCustomizeModalClose}
        onExit={handleOnExit}
      />
    </CookieConsentDialogContainer>
  );
}
