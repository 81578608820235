import { darkTheme, styled, Checkbox } from '@rango-dev/ui';

export const CookieConsentDialogContainer = styled('div', {
  width: '312px',
  height: '362x',
  borderRadius: '$xs',
  backgroundColor: '$background',
  position: 'fixed',
  bottom: '$15',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 99999999,

  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral200',
  },

  '@md': {
    marginLeft: '$0',
    borderRadius: '$sm',
    bottom: '$25',
    left: '$25',
    width: '360px',
    height: '340x',
    transform: 'translateX(0)',
  },

  variants: {
    open: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },

  '.cookie-content': {
    width: '100%',
    height: '100%',
    padding: '$25',
    position: 'relative',

    '.cookie-title': {
      width: '100%',
      textAlign: 'left',
    },

    '.cookie-description': {
      width: '100%',
      textAlign: 'left',

      [`.${darkTheme} &`]: {
        color: '$neutral800',
      },

      '.cookie-link': {
        color: '$info',
        padding: '$4',
        textDecoration: 'underline',
      },
    },

    '.cookie-button': {
      padding: '$6',
    },

    '.outline-button': {
      background: 'none',
      [`.${darkTheme} &`]: {
        color: '$secondary250',
        border: '1px solid $secondary250',
      },
    },
  },
});

export const CloseButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'none',
  position: 'absolute',
  right: '$15',
  top: '$15',
  width: '$16',
  height: '$16',
});

export const CustomizeCookiesModalContent = styled('div', {
  padding: '0 $5',

  '.customize-cookie-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '.customize-cookie-description': {
    textAlign: 'left',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },

  '.button-container': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '$15',

    button: {
      width: '100%',
      height: '40px',
    },

    '.outline-button': {
      [`.${darkTheme} &`]: {
        color: '$secondary250',
        border: '1px solid $secondary250',
      },
    },

    '@md': {
      rowGap: 'unset',
      flexDirection: 'row',
      justifyContent: 'space-between',
      button: {
        width: '248px',
      },
    },
  },
});

export const CustomizeCookiesModalDivider = styled('div', {
  height: '1px',
  width: '100%',
  background: '$neutral300',
  [`.${darkTheme} &`]: {
    color: '$neutral400',
  },
});
export const ButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '$15',

  button: {
    width: '100%',
    height: '40px',
  },

  '.outline-button': {
    [`.${darkTheme} &`]: {
      color: '$secondary250',
      border: '1px solid $secondary250',
    },
  },

  '@md': {
    rowGap: 'unset',
    flexDirection: 'row',
    justifyContent: 'space-between',
    button: {
      width: '248px',
    },
  },
});

export const CustomizeCookiesModalHeader = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',

  '.customize-cookie-title': {
    flexGrow: 1,
    textAlign: 'left',
    '@md': {
      textAlign: 'center',
    },
  },
});

export const CustomizeCookiesModalStyles = {
  container: {
    borderRadius: '$primary $primary 0px 0px',
    width: '100%',
    padding: '$20',

    '@md': {
      borderRadius: '$primary',
      width: '570px',
      height: '423px',
      padding: '$30',
      boxShadow: '-10px 10px 30px 0px rgba(0, 0, 0, 0.05)',

      [`.${darkTheme} &`]: {
        boxShadow: '0px 5px 20px 0px rgba(130, 130, 130, 0.20)',
      },
    },
    transition: 'width .45s, height .45s, transform .45s ease-in-out, top .45s ease-in-out',
  },
  content: {
    overflowY: 'clip',
    position: 'relative',
    padding: 0,
  },
};

export const CustomizeModalCloseButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
});

export const CheckboxStyle = styled(Checkbox, {
  '&[data-disabled]': {
    [`.${darkTheme} &`]: {
      boxShadow: '0px 5px 20px 0px rgba(130, 130, 130, 0.20)',
    },
  },
});
