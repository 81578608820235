import React, { FunctionComponent, useEffect, useState } from 'react';
import { AFFILIATE_REF, WalletTypes } from '../utils/Constants';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { SimpleNotification } from '../components/SimpleNotification';
import { loadStatus } from '../state/StatusSlice';
import { useLocation } from 'react-router-dom';
import { getServerStatus } from '../api/Status';
import { useTranslation } from 'react-i18next';
import ServerStatus from '../components/ServerStatus';
import { Widget } from '../components/Widget';
import { useForceAutoConnect } from '../hooks/useForceAutoConnect';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { handlePreviousURLs } from '../utils/generalFunctions/common';
import { SwapToast } from '../components/SwapToast';
import { useWidget } from '@rango-dev/widget-embedded';
import { Divider } from '@rango-dev/ui';
import { useCheckWidgetExpanded } from '../hooks/useCheckWidgetExpanded';
import { updateWidgetConfig } from '../state/WidgetSlice';
import { WIDGET_BASE_ROUTE } from '../constants/navigation';
import { Helmet } from 'react-helmet';

export const Swap: FunctionComponent = () => {
  const [serverErrorOpen, setServerErrorOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isActiveTab } = useWidget();
  const { isExpanded } = useCheckWidgetExpanded();

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const loadingMetaStatus = useAppSelector((state) => state.meta.loadingStatus);
  const metaTitle = t('Rango Exchange | Fast, Secure Cross-Chain Swap and Bridge');
  const metaDescription = t(
    'Use Rango Exchange for secure cross-chain bridge and swaps. Transfer assets seamlessly and effortlessly between blockchains with our fast, user-friendly platform.',
  );

  useForceAutoConnect({
    isActiveTab,
    loadingStatus: loadingMetaStatus,
    walletType: WalletTypes.SAFE,
  });

  useEffect(() => {
    if (loadingMetaStatus === 'failed') setServerErrorOpen(true);
  }, [loadingMetaStatus]);

  useEffect(() => {
    const ref = searchParams.get('ref');
    if (!!ref) {
      localStorage.setItem(AFFILIATE_REF, ref);
      dispatch(
        updateWidgetConfig({
          affiliate: {
            ref,
          },
        }),
      );
    }
  }, [searchParams]);

  useEffect(() => {
    getServerStatus()
      .then((status) => {
        if (!status) return;
        dispatch(loadStatus(status));
      })
      .catch(() => {
        setServerErrorOpen(true);
      });
  }, [dispatch]);
  function handleNavigation(params: URLSearchParams) {
    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith('utm_')) {
        params.set(key, value);
      }
    }
    navigate(
      {
        pathname: WIDGET_BASE_ROUTE,
        search: `?${params.toString()}`,
      },
      { replace: true },
    );
  }

  useEffect(() => {
    if (!pathname) return;

    const segments = pathname.split('/').filter(Boolean);
    if (segments[0] === 'swap') {
      // This is here to make it compatible with legacy URLs
      const newUrl = handlePreviousURLs(pathname);
      const queryParams = new URLSearchParams(newUrl || {});
      const amount = searchParams.get('a');
      const referrer = searchParams.get('referrer');

      if (amount) queryParams.set('fromAmount', amount);
      if (referrer) queryParams.set('autoConnect', referrer);

      handleNavigation(queryParams);
    }
  }, [pathname]);

  return (
    <div className="relative">
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <SimpleNotification
        message={t('Error connecting server, please refresh the page and try again.')}
        severity="error"
        open={serverErrorOpen}
        horizontal="center"
        setOpen={(o) => setServerErrorOpen(o)}
      />

      <div>
        <SwapToast />

        <div className="relative mx-auto flex min-h-80 justify-center rounded-2xl pb-1">
          <Widget />
        </div>
      </div>

      <React.Suspense fallback={<div />}>
        <ServerStatus />
      </React.Suspense>
      <Divider size={24} />
    </div>
  );
};
