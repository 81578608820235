export const USER_COOKIE_CONSENT = 'USER_COOKIE_CONSENT';
export const USER_COOKIE_CONSENT_EXPIRATION_DAYS = 180;
export const COOKIE_DOMAIN = '.rango.exchange';
export const DAY_IN_SECOND = 24 * 60 * 60;

export const GTM_COOKIE_NAME = [
  { name: 'googleAnalytics', value: '_ga' },
  { name: 'beamer', value: '_BEAMER' },
  { name: 'general clarity', value: '_clsk' },
  { name: 'general clarity', value: '_clck' },
  { name: 'CLID clarity', value: 'CLID' },
  { name: 'MUID clarity', value: 'MUID' },
];

export const COOKIE_CONSENT_VALUE = {
  granted: 'granted',
  denied: 'denied',
};
