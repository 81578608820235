import {
  Bengali,
  Catalonia,
  Chinese,
  Denmark,
  English,
  Finland,
  French,
  German,
  Greece,
  Hungary,
  India,
  Indonesian,
  Italian,
  Japanese,
  Korea,
  Lithuania,
  Malay,
  Netherlands,
  Pakistan,
  Philippines,
  Poland,
  Portuguese,
  Russian,
  SaudiArabia,
  Serbia,
  Slovakia,
  SouthAfrica,
  Spanish,
  Swahili,
  Swedish,
  Thai,
  Turkish,
  Ukrainian,
  Vietnamese,
} from '@rango-dev/ui';
import type { LanguageType } from '../utils/types';

export const DEFAULT_LANGUAGE = 'en';
export const LOCALE_COOKIE = 'USER_LOCALE';
export const EXPIRATION_LOCALE_COOKIE_DAYS = 365; // 365 days

export const Languages: LanguageType[] = [
  { title: 'Afrikaans', value: 'af', Icon: SouthAfrica },
  { title: 'عربي', value: 'ar', Icon: SaudiArabia },
  { title: 'বাংলা', value: 'bn', Icon: Bengali },
  { title: 'Català', value: 'ca', Icon: Catalonia },
  { title: '简体中文', value: 'zh-CN', Icon: Chinese },
  { title: '中文（繁體)', value: 'zh-TW', Icon: Chinese },
  { title: 'Dansk', value: 'da', Icon: Denmark },
  { title: 'Nederlands', value: 'nl', Icon: Netherlands },
  { title: 'English', value: 'en', Icon: English },
  { title: 'Filipino', value: 'fil', Icon: Philippines },
  { title: 'Suomalainen', value: 'fi', Icon: Finland },
  { title: 'Français', value: 'fr', Icon: French },
  { title: 'Deutsch', value: 'de', Icon: German },
  { title: 'ελληνικά', value: 'el', Icon: Greece },
  { title: 'हिंदी', value: 'hi', Icon: India },
  { title: 'Magyar', value: 'hu', Icon: Hungary },
  { title: 'Indonesia', value: 'id', Icon: Indonesian },
  { title: 'Italiana', value: 'it', Icon: Italian },
  { title: '日本語', value: 'ja', Icon: Japanese },
  { title: '한국인', value: 'ko', Icon: Korea },
  { title: 'Lietuvių', value: 'lt', Icon: Lithuania },
  { title: 'Melayu', value: 'ms', Icon: Malay },
  { title: 'Polski', value: 'pl', Icon: Poland },
  { title: 'Português', value: 'pt', Icon: Portuguese },
  { title: 'Русский', value: 'ru', Icon: Russian },
  { title: 'Cрпски', value: 'sr', Icon: Serbia },
  { title: 'Slovenský', value: 'sk', Icon: Slovakia },
  { title: 'Español', value: 'es', Icon: Spanish },
  { title: 'Kiswahili', value: 'sw', Icon: Swahili },
  { title: 'Svenska', value: 'sv', Icon: Swedish },
  { title: 'แบบไทย', value: 'th', Icon: Thai },
  { title: 'Türkçe', value: 'tr', Icon: Turkish },
  { title: 'Yкраїні', value: 'uk', Icon: Ukrainian },
  { title: 'اردو', value: 'ur', Icon: Pakistan },
  { title: 'Tiếng Việt', value: 'vi', Icon: Vietnamese },
];
