import { Button, darkTheme, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '14px $20 $20 $20',
  gap: '$15',
  '@sm': {
    margin: '44px $24 $30 $24',
    flex: 1,
  },
  '@md': {
    flexDirection: 'column',
    gap: '$25',
    padding: 'unset',
  },
  '@media (min-width: 1280px)': {
    flexDirection: 'row',
  },
});

export const MainSection = styled('div', {
  borderRadius: '$primary',
  background: '$background',
  flex: '1',
  display: 'flex',
  alignContent: 'center',
  padding: '$30 $20',
  '@media (min-width: 1280px)': {
    padding: '35px',
    placeSelf: 'stretch',
  },
});

export const Content = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '$15',
});

export const Steps = styled('div', {
  padding: '$30 0 50px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .step-separator-container': {
    padding: '0 1px 0 $2',
  },
});

export const Step = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const UnauthenticatedImage = styled('img', {
  width: '100%',
  height: 'auto',

  '@sm': {
    width: '360px',
  },
});

export const StepTitle = styled(Typography, {
  position: 'absolute',
  top: 'calc(100% + $8)',
  width: 'max-content',
  variants: {
    disabled: {
      true: {
        color: '$neutral700 !important',
        [`.${darkTheme} &`]: {
          color: '$neutral900 !important',
        },
      },
    },
  },
});

export const StepNumber = styled('div', {
  width: '$32',
  height: '$32',
  borderRadius: '100%',
  border: '1px solid $secondary',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    done: {
      true: {
        backgroundColor: '$secondary',
      },
      false: {
        background: 'inherit',
      },
    },
    disabled: {
      true: {
        borderColor: '$neutral700',
        [`.${darkTheme} &`]: {
          borderColor: '$neutral900',
        },
        '& span': {
          color: '$neutral700',
          [`.${darkTheme} &`]: {
            color: '$neutral900',
          },
        },
      },
    },
  },
});

export const StyledButton = styled(Button, {
  width: '170px',
  '@sm': {
    width: '290px',
  },
});

export const ButtonContent = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const Description = styled(Typography, {
  color: '$neutral700 !important',
  [`.${darkTheme} &`]: {
    color: '$neutral900 !important',
  },
});

export const SidebarContainer = styled('div', {
  justifySelf: 'start',
});

export const MobileSideBarContainer = styled('div', {
  paddingBottom: '$46',
});
