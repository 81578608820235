import { darkTheme, styled, Tabs, theme } from '@rango-dev/ui';

export const Container = styled('div', {
  textAlign: 'start',
  borderRadius: '$primary',
  background: '$background',
  minHeight: '50%',
  '@sm': {
    padding: '35px',
    display: 'flex',
    flexDirection: 'column',
    gap: '$25',
  },
});

export const TabsContainer = styled('div', {
  padding: '$10 0',
  width: '100%',
  '@sm': {
    width: '400px',
    padding: 'unset',
  },
});

export const HeaderContainer = styled('div', {
  padding: '$20',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  '@sm': {
    padding: 0,
  },
});

export const ContentContainer = styled('div', {
  padding: '$20',
  '@sm': {
    padding: 0,
  },
});

export const StyledTabs = styled(Tabs, {
  variants: {
    isMobile: {
      true: {
        '& ._tab': {
          '&[data-active="false"]': { color: theme.colors.neutral600 },
        },
        '&:nth-child(1)': {
          '& > :nth-child(1)': {
            paddingLeft: '$20',
          },
          '& > :nth-child(4)': {
            paddingRight: '$20',
          },
        },
        '@sm': {
          '&:nth-child(1)': {
            '& > :nth-child(1)': {
              paddingLeft: 'unset',
            },
            '& > :nth-child(4)': {
              paddingRight: 'unset',
            },
          },
        },
      },
      false: {
        '& ._tab': {
          fontWeight: '$regular',
          '&[data-active="false"]': {
            color: '$neutral600',
            [`.${darkTheme} &`]: {
              color: '$neutral800',
            },
          },
        },
      },
    },
  },
});

export const CardsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@sm': {
    gridTemplateColumns: 'repeat(auto-fill,minmax(300px, 1fr))',
  },
  gap: '$20',
});
