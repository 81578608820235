import { darkTheme, styled } from '@rango-dev/ui';
import { Link } from 'react-router-dom';

export const MobileMenuContainer = styled('div', {
  width: '100%',
  height: '75px',
  boxShadow: '0px -10px 20px 0px rgba(28, 60, 241, 0.07)',
  backgroundColor: '$background',
  borderTopLeftRadius: '$xm',
  borderTopRightRadius: '$xm',
  flexShrink: 0,
  padding: '$0 $20',
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',

  '@sm': {
    display: 'none',
  },
});

export const MobileMenuItem = styled(Link, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',

  variants: {
    isActive: {
      true: {
        '._typography': {
          color: '$primary !important',
        },
        '._icon': {
          color: '$primary !important',
        },
      },
    },
  },

  [`.${darkTheme} &`]: {
    '._typography': {
      color: '$neutral900',
    },
    '._icon': {
      color: '$neutral800',
    },
  },

  '.new-badge-circle-container': {
    position: 'absolute',
    top: '15px',
    right: 'calc(50% - 13px)',
    width: '$10',
    height: '$10',
    borderRadius: '50%',
    backgroundColor: '$background',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '.new-badge-circle': {
      width: '$6',
      height: '$6',
      borderRadius: '50%',
      backgroundColor: '$error500',
    },
  },
});

export const MoreButton = styled('button', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  cursor: 'pointer',

  [`.${darkTheme} &`]: {
    '._typography': {
      color: '$neutral900',
    },
    '._icon': {
      color: '$neutral800',
    },
  },
});

export const MoreContainer = styled('div', {
  width: '230px',
  height: '334px',
  padding: '20px',
  boxShadow: '0px 5px 20px 0px rgba(130, 130, 130, 0.20)',
  background: '$background',
  borderRadius: '$sm',
});

export const MoreItem = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  padding: '$12 $0',

  '&:first-child': {
    paddingTop: '$0',
  },

  [`.${darkTheme} &`]: {
    '._icon': {
      color: '$foreground',
    },
  },
});

export const MoreDivider = styled('div', {
  height: '1px',
  width: '100%',
  backgroundColor: '$neutral300',
});

export const SocialContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  svg: {
    width: '$24',
  },
});
