import { Button, darkTheme, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  borderRadius: '$primary',
  background: '$background',
  textAlign: 'left',
  padding: '$20 0',
  '@sm': {
    minHeight: '666px',
    padding: '35px 0',
    minWidth: '434px',
  },
  '@media (min-width:1280px)': {
    maxWidth: 434,
  },
  '& .current-user-item': {
    padding: '$15 0',
    '@sm': {
      padding: '$15',
    },
    '& .user-item-row': {
      backgroundColor: '$neutral400',
      padding: '$15 $20',
      '@sm': {
        borderRadius: '$primary',
        padding: '$15',
      },
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral300',
      },
    },
    '& .user-item-rank': {
      backgroundColor: '$background',
      [`.${darkTheme} &`]: {
        backgroundColor: '$background',
      },
    },
  },
});

export const Title = styled(Typography, {
  padding: '0 $20',
  '@sm': {
    padding: '0 35px',
  },
});

export const EmptyStateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$28 0',
  height: '355px',
  '@sm': {
    height: '440px',
  },
  '& ._text-description': {
    color: '$neutral700',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});
export const FullLeaderboardContent = styled('div', {
  padding: '$0 $20',
  '@sm': {
    padding: '$0 35px',
  },
});

export const FullLeaderboardButton = styled(Button, {
  '& ._text': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '$4',
  },
});
