import { LogoutIcon } from '@rango-dev/ui';
import { LogOutButton, Container } from './LogOut.styles';
import { useTranslation } from 'react-i18next';
import { SimpleNotification } from '../../SimpleNotification';
import { LogOutModal } from '../LogOutModal';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { logOutUser } from '../../../state/ProfileSlice';
import { logOut } from '../../../api/Profile';
import { Tooltip } from '../../common/Tooltip';
import { getContainer } from '../../../utils/generalFunctions/common';
import { useNavigate } from 'react-router-dom';

export function LogOut() {
  const { loading } = useAppSelector((state) => state.profile.profileSummary);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const [logOutInProgress, setLogOutInProgress] = useState(false);
  const [showLogOutError, setShowLogOutError] = useState(false);

  const onLogOut = async () => {
    setLogOutInProgress(true);
    try {
      await logOut();
      dispatch(logOutUser());
      navigate('../profile', { replace: true });
    } catch (error) {
      setShowLogOutError(true);
    } finally {
      setLogOutInProgress(false);
    }
  };

  return (
    <Container className="id">
      <SimpleNotification
        message={t('An error occurred during logout. Please try again.')}
        open={showLogOutError}
        setOpen={setShowLogOutError}
        severity="error"
      />
      <LogOutModal
        open={showLogOutModal}
        logOutInProgress={logOutInProgress}
        onClose={() => setShowLogOutModal(false)}
        onLogOut={onLogOut}
      />
      <Tooltip
        content={t('Logout')}
        side="bottom"
        sideOffset={14}
        showInTouchDevices={false}
        container={getContainer()}>
        <LogOutButton
          variant="outlined"
          type="secondary"
          size="medium"
          disabled={loading}
          onClick={() => setShowLogOutModal(true)}>
          <LogoutIcon size={16} color={loading ? 'gray' : 'secondary'} />
        </LogOutButton>
      </Tooltip>
    </Container>
  );
}
