import { ReactElement } from 'react';
import { ProfileSummaryItems } from '../ProfileSummaryItems';
import { Container } from './ProfileSummary.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ProfileSummaryEmptyState } from './ProfileSummaryEmptyState';
import { ProfileFavorites } from '../ProfileFavorites';
import { Divider } from '@rango-dev/ui';

export function ProfileSummary(): ReactElement {
  const { error } = useAppSelector((state) => state.profile.profileSummary);

  return (
    <Container>
      {error ? (
        <ProfileSummaryEmptyState />
      ) : (
        <div className="summary-content">
          <div className="summary-items">
            <ProfileSummaryItems />
          </div>
          <Divider size={16} />
          <ProfileFavorites />
        </div>
      )}
    </Container>
  );
}
