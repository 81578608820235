import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { FavoriteItem } from './FavoriteItem';
import { Container } from './ProfileFavorites.styles';

export function ProfileFavorites(): ReactElement {
  const { data: profileSummary } = useAppSelector((state) => state.profile.profileSummary);
  const { t } = useTranslation();
  return (
    <Container>
      {profileSummary?.mostUsedToken && (
        <FavoriteItem
          title={t('Favorite Token')}
          logo={profileSummary.mostUsedToken.logo}
          name={profileSummary.mostUsedToken.symbol}
        />
      )}
      {profileSummary?.mostUsedDex && (
        <FavoriteItem
          title={t('Favorite DEX')}
          logo={profileSummary.mostUsedDex.logo}
          name={profileSummary.mostUsedDex.title}
        />
      )}
      {profileSummary?.mostUsedBlockchain && (
        <FavoriteItem
          title={t('Favorite Chain')}
          logo={profileSummary.mostUsedBlockchain.logo}
          name={profileSummary.mostUsedBlockchain.shortName}
        />
      )}
      {profileSummary?.mostUsedBridge && (
        <FavoriteItem
          title={t('Favorite Bridge')}
          logo={profileSummary.mostUsedBridge.logo}
          name={profileSummary.mostUsedBridge.title}
        />
      )}
    </Container>
  );
}
